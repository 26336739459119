import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import Button from './Button';
import device from '../utils/device';
import loginIcon from '../images/siteLoginMove.svg';
import { NavigationItem } from '../types';

interface NavigationProps {
  navItems: Array<NavigationItem>;
}

const NavigationStyles = styled.nav`
  font-weight: 500;
  line-height: 1.375rem;
  background: linear-gradient(
    0deg,
    rgba(5, 120, 117, 1) 0%,
    rgba(37, 32, 57, 1) 20%
  );
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  @media ${device.laptop} {
    background: none;
    z-index: auto;
    width: auto;
    height: 26px;
    position: static;
    display: flex;
    button {
      display: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    @media ${device.laptop} {
      background: none;
      z-index: auto;
      width: auto;
      height: 26px;
      display: flex;
      align-items: flex-end;
    }
    li {
      transition: filter 0.5s ease-in;
      display: flex;
      justify-content: center;
      padding: 1.25rem 0;
      @media ${device.laptop} {
        padding: 0;
        display: block;
      }
    }
    &:hover li {
      filter: blur(3px);
    }
    li:hover {
      filter: none;
    }
    a:hover {
      text-decoration: none;
    }
    .login-link {
      display: flex;
      div {
        margin-right: 0.625rem;
        white-space: nowrap;
        line-height: 1.125rem;
      }

      img {
        display: block;
      }
      @media ${device.laptop} {
        display: none;
      }
    }
  }
  a {
    margin: 0 0.938rem;
  }
`;

const Navigation = ({ navItems }: NavigationProps) => {
  return (
    <NavigationStyles>
      <ul>
        {navItems.map(
          ({ Component, path, name }) =>
            name !== 'Home' && (
              <li key={path}>
                {Component !== null ? (
                  <NavLink
                    to={path}
                    activeStyle={{
                      fontWeight: 600,
                    }}
                  >
                    {name}
                  </NavLink>
                ) : (
                  <a href={path} target="_blank" rel="noreferrer">
                    {name}
                  </a>
                )}
              </li>
            )
        )}
        <li>
          <Button href="/dashboard/" className="login-link" primary>
            <div>Dashboard</div>
            <img src={loginIcon} alt="External link" />
          </Button>
        </li>
        <li>
          <Button href="/app/" className="login-link">
            Login to data portal
          </Button>
        </li>
      </ul>
    </NavigationStyles>
  );
};

export default Navigation;
