import { gsap } from 'gsap';

export const animateElements = (
  element: Element,
  delay: number,
  scrollTriggerElement: Element | null = null
) => {
  const fromOptions = {
    autoAlpha: 0,
    y: 20,
  };
  const toOptions = {
    autoAlpha: 1,
    y: 0,
    ease: 'elastic.out(1, 0.3)',
    duration: 5,
    delay,
  };
  let scrollOptions = {};
  if (scrollTriggerElement) {
    scrollOptions = {
      ...toOptions,
      scrollTrigger: {
        scroller: '.container',
        trigger: scrollTriggerElement,
        start: 'top center',
        end: 'bottom center',
        toggleActions: 'play none none none',
      },
    };
  }
  gsap.fromTo(element, fromOptions, { ...toOptions, ...scrollOptions });
};

export const animateSection = (
  section: Element,
  animateOnScroll = false,
  initialDelay = 0
) => {
  let delay = initialDelay;
  const elements = section.getElementsByClassName('animate-reveal');

  for (let i = 0; i < elements.length; i++) {
    delay += 0.25;
    animateElements(elements[i], delay, animateOnScroll ? section : null);
  }
};
