import styled from 'styled-components';

interface ButtonProps {
  children?: React.ReactChild | React.ReactChild[];
  className?: string;
  href?: string;
  onClick?: () => void;
  primary?: boolean;
  submit?: boolean;
}

const ButtonStyles = styled.button<{
  primary: boolean;
  submit?: boolean;
  as?: React.ElementType;
  href?: string;
  rel?: string;
}>`
  background: ${(props) =>
    props.primary
      ? 'var(--lightGreen)'
      : props.submit
      ? '#818181'
      : 'rgba(255, 255, 255, 0)'};
  transition: all 0.5s ease-in;
  transition-property: color, background, border;
  color: ${(props) =>
    props.primary
      ? 'var(--darkPurple)'
      : props.submit
      ? '#1C1A26'
      : 'var(--white)'};
  border: 1px solid
    ${(props) =>
      props.primary ? '#00e5af' : props.submit ? '#818181' : 'var(--white)'};
  padding: 0.9rem 1.25rem;
  border-radius: 2rem;
  font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  height: 48px;
  display: inline-block;
  &:hover {
    background: ${(props) =>
      props.primary
        ? '#00b588'
        : props.submit
        ? '#818181'
        : 'var(--lightGreen)'};
    color: ${(props) =>
      props.primary
        ? 'var(--white)'
        : props.submit
        ? '#1C1A26'
        : 'var(--darkPurple)'};
    border: 1px solid
      ${(props) =>
        props.primary
          ? '#00b588'
          : props.submit
          ? '#818181'
          : 'var(--lightGreen)'};
    text-decoration: none;
  }
`;

const Button = ({
  children,
  className,
  href,
  onClick,
  primary = false,
  submit = false,
}: ButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return href ? (
    <ButtonStyles
      as="a"
      className={className}
      href={href}
      primary={primary}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </ButtonStyles>
  ) : (
    <ButtonStyles
      className={className}
      onClick={handleClick}
      type={submit ? 'submit' : 'button'}
      primary={primary}
      submit={submit}
    >
      {children}
    </ButtonStyles>
  );
};

export default Button;
