import { gsap } from 'gsap';
import { isMobile, isTablet } from 'mobile-device-detect';
import { useEffect, useRef } from 'react';

import { animateSection } from '../utils/animation';
import Hero from '../components/Hero';
import PageSection from '../components/PageSection';
import Spacer from '../components/Spacer';

import ImageOne from '../images/how-it-works-1.png';
import ImageOne2x from '../images/how-it-works-1@2x.png';
import ImageOne3x from '../images/how-it-works-1@3x.png';
import ImageTwo from '../images/how-it-works-2.png';
import ImageTwo2x from '../images/how-it-works-2@2x.png';
import ImageTwo3x from '../images/how-it-works-2@3x.png';
import ImageFour from '../images/how-it-works-4.png';
import ImageFour2x from '../images/how-it-works-4@2x.png';
import ImageFour3x from '../images/how-it-works-4@3x.png';
import VerticalCarousel from '../components/VerticalCarousel';
import useGaTracker from '../components/useGaTracker';
import { Helmet } from 'react-helmet';

const VERTICAL_CAROUSEL_DATA = [
  {
    id: 1,
    src: 'reef-image4',
    title: 'Collect & manage monitoring images',
    copy: 'Submit your photos of the reef along with associated data - location, date, habitat, depth - to help rapidly assess the condition of coral reefs. An automated pipeline secures and organises this data and there are options to share data at multiple levels to foster collaboration between scientists and organisations.',
  },
  {
    id: 2,
    src: 'reef-image5',
    title: 'Analyse & automatically annotate images using AI',
    copy: 'ReefCloud is designed to machine-learn from experts to automate image analysis and extract relevant information, such as coral cover and reef composition quickly and efficiently. This helps scientists to: \n\nValidate automated methods\nIncrease data analysis efficiency\nExtract advanced taxonomic detail from images',
  },
  {
    id: 3,
    src: 'reef-image3',
    title: 'Synthesise insights & view automatically updated reports',
    copy: 'Interactive dashboards, report cards and data access tools enable rapid interpretation, reporting and communication on coral reef conditions across geographies.',
  },
  {
    id: 4,
    src: 'vert-carousel-4',
    title: 'Share, collaborate & conserve',
    copy: 'Manage and share your data to collaborate and generate robust and timely advice for managing coral reefs. Better and more readily available knowledge supports smarter decisions to protect our coral reefs now and in the future.',
  },
];

const HowItWorks = () => {
  useGaTracker('How It Works');
  const heroRef = useRef(null);
  const headingRef = useRef(null);
  const verticalCarouselRef = useRef(null);
  const storiesHeadingRef = useRef(null);
  const storyOneRef = useRef(null);
  const storyTwoRef = useRef(null);
  const storyThreeRef = useRef(null);

  useEffect(() => {
    let tl: any;

    const elements = [
      heroRef.current,
      !isMobile && !isTablet ? verticalCarouselRef.current : null,
      storiesHeadingRef.current,
      storyOneRef.current,
      storyTwoRef.current,
      storyThreeRef.current,
    ];

    // const heroElement: any = heroRef.current;
    // animateSection(heroElement, false, 1.5);

    if (!isMobile && !isTablet) {
      const verticalCarouselElement: any = verticalCarouselRef.current;
      const slider = gsap.utils.toArray('#slider', verticalCarouselElement);

      const slides: Array<HTMLDivElement> = gsap.utils.toArray(
        '.slide',
        verticalCarouselElement
      );

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: '.container',
          pin: true,
          pinType: 'fixed',
          pinReparent: true,
          start: 'top top',
          end: '+=400%',
          scrub: true,
          trigger: verticalCarouselElement,
          snap: 1 / slides.length,
        },
      });

      let sliderYPos = 0;
      slides.forEach((slide, i) => {
        sliderYPos += 100;
        if (i !== slides.length - 1) {
          tl.set(slide, {
            autoAlpha: 1,
            y: -220,
          })
            .to(slide, {
              autoAlpha: 0,
              scaleX: 0.9,
              scaleY: 0.9,
              y: -360,
              duration: 1,
            })
            .set(slides[i + 1], {
              autoAlpha: 0,
              scaleX: 0.9,
              scaleY: 0.9,
            });

          tl.to(slides[i + 1], {
            ease: 'none',
            autoAlpha: 1,
            scaleX: 1,
            scaleY: 1,
            y: -220,
            duration: 1,
          });
          tl.to(
            slider,
            {
              y: `+${sliderYPos}`,
              ease: 'none',
              duration: 2,
            },
            '-=2'
          );
        }

        tl.to({}, { duration: 1 });
      });
    }

    elements.forEach((el, index) => {
      const delay = index === 0 ? 1.5 : 0;
      const animateOnScroll = index === 0 ? false : true;
      animateSection(el!, animateOnScroll, delay);
    });

    return function cleanup() {
      tl.kill();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>ReefCloud - How it works</title>
      </Helmet>
      <Hero ref={heroRef}>
        <h1 className="alternate animate-reveal">
          Your photos and insights help drive actions.
        </h1>
        <p className="animate-reveal">
          ReefCloud allows you to manage your imagery in the field and upload it
          to the cloud. Its automated analysis can replicate expert observations
          with 80-90% confidence to produce accurate estimates of coral reef
          benthic composition 700 times faster than manual assessment.
        </p>
      </Hero>
      {!isMobile && !isTablet && (
        <VerticalCarousel
          ref={verticalCarouselRef}
          data={VERTICAL_CAROUSEL_DATA}
        />
      )}
      {(isMobile || isTablet) && (
        <PageSection ref={headingRef}>
          <h3 className="alternate">How to use ReefCloud</h3>
        </PageSection>
      )}
      {(isMobile || isTablet) &&
        VERTICAL_CAROUSEL_DATA.map((item) => {
          return (
            <PageSection key={item.id}>
              <div>
                <img
                  src={`images/${item.src}.png`}
                  srcSet={`images/${item.src}@2x.png 2x, images/${item.src}@3x.png 3x`}
                  alt={item.title}
                />
                <div className="section-content">
                  <span className="title alternate">{item.title}</span>
                  <p className="copy">{item.copy}</p>
                </div>
              </div>
            </PageSection>
          );
        })}
      <PageSection ref={storiesHeadingRef}>
        <h3 className="alternate animate-reveal">How we developed ReefCloud</h3>
      </PageSection>
      <PageSection ref={storyOneRef}>
        <img
          className="animate-reveal"
          src={ImageOne}
          srcSet={`${ImageOne2x} 2x, ${ImageOne3x} 3x`}
          alt="Artificial Intelligence accelerating coral reef monitoring"
        />
        <div className="section-content animate-reveal">
          <span className="title alternate">
            Artificial Intelligence accelerating coral reef monitoring
          </span>
          <p className="copy">
            To understand the health of our coral reefs and observe the impacts
            of disturbances such as climate change, regular monitoring is
            essential. Traditionally, the amount of data coral reef scientists
            can collect has been limited. Now, thanks to high-quality underwater
            cameras, collecting data in the field is much easier, however,
            analysing photos is still a slow and painstaking process.
          </p>
          <p className="copy">
            The use of machine learning (ML) algorithms to learn from experts to
            rapidly identify organisms from benthic photographs has vastly
            improved the efficiency of what we do. What would take coral reef
            scientists 10-15 minutes now takes the machine a few seconds.
          </p>
        </div>
      </PageSection>
      <PageSection reverse ref={storyTwoRef}>
        <img
          className="animate-reveal"
          src={ImageTwo}
          srcSet={`${ImageTwo2x} 2x, ${ImageTwo3x} 3x`}
          alt="Our approach"
        />
        <div className="section-content animate-reveal">
          <span className="title alternate">Our approach</span>
          <p className="copy">
            The{' '}
            <a
              href="https://www.aims.gov.au/docs/research/monitoring/reef/reef-monitoring.html"
              target="_blank"
              rel="noreferrer"
            >
              AIMS Long Term Monitoring Program (LTMP)
            </a>{' '}
            performs annual, image-based surveys of 80 reefs on Australia’s
            Great Barrier Reef. This dataset consists of millions of
            quality-controlled point annotations made by expert coral reef
            ecologists. We use this large, high-quality dataset to train a
            machine learning model. As new images are uploaded to ReefCloud by
            users, the trained model converts them into feature vectors which
            are classified into coral reef categories using smaller, faster
            classification machine learning models.
          </p>
          <p className="copy">
            Users need only label a part of their dataset to aid the model to
            pick out the specifics of the new images and provide accurate
            labels. Users can then output a spreadsheet that contains the
            proportion of different reef species detected in each photo - vital
            data for understanding reef condition.
          </p>
        </div>
      </PageSection>
      <PageSection ref={storyThreeRef}>
        <img
          className="animate-reveal"
          src={ImageFour}
          srcSet={`${ImageFour2x} 2x, ${ImageFour3x} 3x`}
          alt="Machine learning"
        />
        <div className="section-content animate-reveal">
          <span className="title alternate">Machine learning</span>
          <p className="copy">
            A large part of the development process is validating and adjusting
            the models until the machine is performing as well as a human
            annotator. Our goal is to achieve an error rate of less than 2% -
            better than the difference between two human annotators. Just like
            humans, our artificial intelligence finds some benthic components
            trickier to identify than others!
          </p>
          <p className="copy">
            The ability to use models in never before-seen geographies is a
            challenging and critical task, but ReefCloud models are already
            showing promise.
          </p>
        </div>
      </PageSection>
      <Spacer height={60} />
    </>
  );
};

export default HowItWorks;
