import p5 from 'p5';
import * as PIXI from 'pixi.js';
import gsap from 'gsap';

import Particle from './Particle';

class GradientAnimation {
  canvas: HTMLCanvasElement;
  app: PIXI.Application;
  //resolution: number;
  mouse_x: number;
  mouse_y: number;
  mouse_started: boolean;
  scroll_y: number;
  width: number;
  height: number;
  gradPad: number;
  gradBounds: PIXI.Rectangle;
  footer_offset: number;
  page_height: number;
  header_gradient: PIXI.Container;
  footer_gradient: PIXI.Container;
  footer_height: number;

  constructor(canvasEl: HTMLCanvasElement, containerEl: HTMLDivElement) {
    this.canvas = canvasEl;
    const app_options = {
      view: this.canvas,
      //resolution: 1,
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: 0x252039,
      antialias: true,
    };
    this.app = new PIXI.Application(app_options);
    this.app.stage.alpha = 0;
    //this.resolution = 1;
    //console.log(this.resolution)
    this.mouse_x = window.innerWidth / 2;
    this.mouse_y = window.innerHeight / 2;
    this.mouse_started = false;
    this.scroll_y = 0 || window.scrollY;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.gradPad = 500;
    this.gradBounds = new PIXI.Rectangle(
      -this.gradPad,
      -this.gradPad,
      this.app.screen.width + this.gradPad * 2,
      this.app.screen.height + this.gradPad * 2
    );
    this.footer_offset = 0;
    this.page_height = 0;

    window.onresize = () => {
      this.app.renderer.resize(window.innerWidth, window.innerHeight);
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      this.gradBounds = new PIXI.Rectangle(
        -this.gradPad,
        -this.gradPad,
        this.app.screen.width + this.gradPad * 2,
        this.app.screen.height + this.gradPad * 2
      );

      const body = document.body,
        html = document.documentElement;
      const height = containerEl.scrollHeight;
      this.footer_offset = Math.max(
        height,
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      this.page_height = height;
    };

    containerEl.addEventListener('scroll', (e) => {
      const target = e.target as HTMLTextAreaElement;
      this.scroll_y = target.scrollTop;
    });

    this.header_gradient = new PIXI.Container();
    this.app.stage.addChild(this.header_gradient);

    this.footer_gradient = new PIXI.Container();
    this.app.stage.addChild(this.footer_gradient);

    this.footer_height = window.innerHeight / 2;
    const body = document.body,
      html = document.documentElement;

    const height = containerEl.scrollHeight;
    this.footer_offset = Math.max(
      height,
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const header_grads: any = [];
    const primaryColors = [
      'rgba(0, 65, 190, 1)', // Dark blue
      'rgba(0, 229, 224, 1)', // Light blue
      'rgba(0, 255, 102, 1)', // Green:
      'rgba(223, 34, 116, 1)', // Pink
      'rgba(255, 0, 35, 1)', // Red
    ];
    const secondaryColors = [
      'rgba(223, 34, 116, 1)', // Pink
      'rgba(223, 34, 116, 1)', // Pink
      'rgba(255, 0, 35, 1)', // Red
      'rgba(255, 0, 35, 1)', // Red
      'rgba(250, 185, 18, 1)', // Yellow
    ];
    const tertiaryColors = [
      'rgba(0, 65, 190, 1)', // Dark blue
      'rgba(0, 65, 190, 1)', // Dark blue
      'rgba(0, 229, 224, 1)', // Light blue
      'rgba(0, 255, 102, 1)', // Green:
    ];
    const offset = window.innerWidth / 16;
    const test = [
      [offset, offset],
      [offset, this.app.screen.height - offset],
      [window.innerWidth - offset, offset],
      [window.innerWidth - offset, this.app.screen.height - offset],
    ];

    this.app.ticker.add(() => {
      this.header_gradient.y = this.scroll_y * -1;
      this.footer_gradient.y =
        this.scroll_y * -1 + (this.footer_offset / 1.1 + this.footer_height);

      /* Loop through all of the header grads */
      for (let i = 0; i < header_grads.length; i++) {
        const particle = header_grads[i];
        const grad = particle.grad;
        grad.x = particle.x;
        grad.y = particle.y;
        particle.update();
      }
      const swirl = p5.Vector.random2D();
      swirl.setMag(0.001);
      const pressure = p5.prototype.createVector(0, -1);
      pressure.setMag(0.001);
    });

    for (let k = 0; k < 4; k++) {
      const x_val = test[k][0];
      const y_val = test[k][1];
      let gradTexture;
      if (x_val <= this.app.screen.width / 2) {
        gradTexture = this.createGradTexture(
          secondaryColors[
            Math.floor(Math.random() * secondaryColors.length) + 0
          ]
        );
      } else {
        gradTexture = this.createGradTexture(
          tertiaryColors[Math.floor(Math.random() * tertiaryColors.length) + 0]
        );
      }
      const particle = new Particle(x_val, y_val, gradTexture, 2500, 1);
      this.header_gradient.addChild(particle.grad);
      header_grads.push(particle);
    }

    /*
      If you want to push the GPU Enable Blur filter and Displacement
    */
    const blurFilter = new PIXI.filters.BlurFilter(10, 4);
    blurFilter.blendMode = PIXI.BLEND_MODES.ADD;
    const alphaFiler = new PIXI.filters.AlphaFilter(0.8);
    alphaFiler.blendMode = PIXI.BLEND_MODES.ADD;
    //const noiseFilter = new PIXI.filters.NoiseFilter(0.05);
    //noiseFilter.blendMode = PIXI.BLEND_MODES.ADD;
    this.footer_gradient.filters = [blurFilter];
    this.header_gradient.filters = [alphaFiler, blurFilter];

    /* 
       Setup Footer Gradient Elements 
    */

    const footer_grads = [];

    for (let j = 0; j < 5; j++) {
      const gradTexture = this.createGradTexture(
        primaryColors[Math.floor(Math.random() * primaryColors.length) + 0]
      );
      const canvas_width = 1000;
      const canvas_height = 1000;
      const min = this.width / 2 - this.width / 4;
      const max = this.width / 2 + this.width;
      const centered_random = Math.floor(Math.random() * (max - min) + min);

      const grad = new PIXI.Sprite(gradTexture);
      grad.rotation = 0;
      //grad.origin_x = (centered_random/this.resolution);
      grad.x = centered_random; // / this.resolution;
      grad.y = -200;
      grad.width = canvas_width;
      grad.height = canvas_height;
      grad.anchor.x = 0.5;
      grad.anchor.y = 0;
      // grad.direction = Math.random() * Math.PI * 2;
      // grad.turningSpeed = Math.random() - 0.0000001;
      // grad.speed = 0.05 + Math.random() * 2;
      // grad.x_amplitude = 2.6180;
      // grad.y_amplitude = Math.floor(Math.random() * 1);
      grad.scale.x = 1.3;
      grad.scale.y = 1;

      this.footer_gradient.addChild(grad);
      footer_grads.push(grad);
    }
  }
  private createGradTexture(color_a = 'red', size = 1700) {
    const width = size;
    const height = size;
    const x = width / 2;
    const y = height / 2;
    const radius = width / 2;
    const firstColor = color_a;
    const secondColor = 'transparent';
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    this.scaleCanvas(canvas, ctx, width, height);
    const gradient = ctx!.createRadialGradient(x, y, 0, x, y, radius);
    gradient.addColorStop(0, firstColor);
    gradient.addColorStop(1, secondColor);
    ctx!.fillStyle = gradient;
    ctx!.beginPath();
    ctx!.arc(x, y, radius, 0, 2 * Math.PI);
    ctx!.fill();
    return PIXI.Texture.from(canvas);
  }
  private scaleCanvas(
    canvas: HTMLCanvasElement,
    context: any,
    width: number,
    height: number
  ) {
    const devicePixelRatio = 1;
    const backingStoreRatio = 1;
    const ratio = devicePixelRatio / backingStoreRatio;
    if (devicePixelRatio !== backingStoreRatio) {
      canvas.width = width * ratio;
      canvas.height = height * ratio;
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
    } else {
      canvas.width = width;
      canvas.height = height;
      canvas.style.width = '';
      canvas.style.height = '';
    }
    context.scale(ratio, ratio);
  }
  public fadeIn() {
    gsap.to(this.app.stage, {
      alpha: 1,
      duration: 1,
    });
  }
  public fadeOut() {
    gsap.to(this.app.stage, {
      alpha: 0,
      duration: 1,
    });
  }
}

export default GradientAnimation;
