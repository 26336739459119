import { Link } from 'react-router-dom';
import { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

import { NavigationItem } from '../types';
import BackToTop from './BackToTop';
import Button from './Button';
import device from '../utils/device';
import InnerStyles from '../styles/InnerStyles';
import SubscribeModal from './SubscribeModal';

import facebookIcon from '../images/facebook.svg';
import instagramIcon from '../images/instagram.svg';
import twitterIcon from '../images/twitter.svg';
import mainAimsLogo from '../images/mainAimsLogo.svg';

interface FooterProps {
  navItems: Array<NavigationItem>;
}

const SOCIAL_LINKS = [
  {
    altText: 'Facebook logo',
    icon: facebookIcon,
    href: 'https://www.facebook.com/australianmarinescience',
  },
  {
    altText: 'Instagram logo',
    icon: instagramIcon,
    href: 'https://au.linkedin.com/company/aims',
  },
  {
    altText: 'Twitter logo',
    icon: twitterIcon,
    href: 'https://twitter.com/aims_gov_au',
  },
];

const FooterStyles = styled.footer`
  background-color: #1c1a26;
  padding-top: 2.25rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  @media ${device.laptop} {
    height: 549px;
    padding-top: 6rem;
  }
  .footer-top {
    padding: 0 var(--gutters);
    @media ${device.laptop} {
      padding: 0 var(--guttersLaptop);
      display: flex;
      justify-content: space-between;
      height: 389px;
    }
    @media ${device.laptopL} {
      padding: 0 var(--guttersLaptopL);
    }
  }
  .aims-logo {
    margin: 0 0 2.25rem 0;
  }
  .subscribe-section {
    p {
      padding: 1rem 0;
      max-width: 400px;
    }
    @media ${device.laptop} {
      display: flex;
      align-items: flex-end;
      margin-right: 5rem;
      h3 {
        margin-top: 0;
        font-size: 1.75rem;
        line-height: 1.75rem;
        font-weight: 500;
        margin: 0;
      }
      p {
        padding-bottom: 0.5rem;
        margin-bottom: 0;
      }
    }
  }
  .back-to-top {
    position: absolute;
    top: 510px;
    right: 20px;
    @media ${device.laptop} {
      top: 294px;
      right: 230px;
    }
  }
  .copyright-section {
    border-top: 1px solid #37353e;
    height: 64px;
  }
  .copyright-links {
    font-size: 0.75rem;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem var(--gutters);
    a {
      padding: 0 0.5rem;
    }
    @media ${device.laptop} {
      padding: 1.5rem var(--guttersLaptop);
    }
    @media ${device.laptopL} {
      padding: 1.5rem var(--guttersLaptopL);
    }
  }
`;

const FooterNavStyles = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: block;
    font-weight: 500;
    padding: 0.625rem 0;
    @media ${device.laptop} {
      &:first-child {
        padding-top: 0;
      }
    }
  }
`;

const SocialLinkStyles = styled.ul`
  display: flex;
  padding: 2rem 0;
  list-style: none;
  @media ${device.laptop} {
    padding-top: 0;
  }
  a {
    background: rgba(255, 255, 255, 0);
    transition: background 0.5s ease-in;
    border: 1px solid var(--white);
    border-radius: 2rem;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.625rem;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }
    img {
      display: block;
    }
  }
`;

const Footer = ({ navItems }: FooterProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  Modal.setAppElement('#root');

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <FooterStyles>
      <InnerStyles>
        <div className="footer-top">
          <div>
            <a href="https://www.aims.gov.au/" target="_blank" rel="noreferrer">
              <img
                className="aims-logo"
                src={mainAimsLogo}
                alt="Logo for AIMS Australia"
              />
            </a>
          </div>
          <FooterNavStyles>
            <ul>
              {navItems.map(
                ({ name, path }) =>
                  name !== 'Home' && (
                    <li key={path}>
                      <Link to={path}>{name}</Link>
                    </li>
                  )
              )}
            </ul>
          </FooterNavStyles>
          <div>
            <div className="subscribe-section">
              <div>
                <h3>Stay connected</h3>
                <p>
                  Insights, updates and inspiring stories from the underwater
                  world of reefs.
                </p>
              </div>
              <Button onClick={openModal}>Subscribe</Button>
            </div>
            <SocialLinkStyles>
              {SOCIAL_LINKS.map((link, index) => (
                <li key={index}>
                  <a href={link.href} target="_blank" rel="noreferrer">
                    <img
                      src={link.icon}
                      alt={link.altText}
                      width="20px"
                      height="20px"
                    />
                  </a>
                </li>
              ))}
            </SocialLinkStyles>
          </div>
        </div>
        <BackToTop
          duration={2500}
          toId="container"
          scrollContainer={document.querySelector('.container')}
        />
      </InnerStyles>
      <div className="copyright-section">
        <InnerStyles>
          <div className="copyright-links">
            <div>
              &copy; {new Date().getFullYear()} ReefCloud - an initiative of the
              Australian Institute of Marine Science
            </div>
            <div>
              <a
                href="https://docs.reefcloud.ai/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of service
              </a>{' '}
              |{' '}
              <a
                href="https://www.aims.gov.au/docs/privacy-policy.html"
                target="_blank"
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </div>
          </div>
        </InnerStyles>
      </div>
      <Modal
        closeTimeoutMS={200}
        contentLabel="modal"
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <SubscribeModal onCloseHandler={closeModal} />
      </Modal>
    </FooterStyles>
  );
};

export default Footer;
