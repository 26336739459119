import { forwardRef } from 'react';
import styled from 'styled-components';

import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';

type HeroProps = {
  children: Array<JSX.Element> | JSX.Element;
};

const HeroStyles = styled(BaseSectionStyles)`
  font-size: 1.125rem;
  div {
    max-width: 730px;
    padding-bottom: 12.5rem;
  }
  h1 {
    margin: 17.5rem 0 1.75rem;
    font-size: 2.125rem;
    font-weight: 400;
    line-height: 3rem;
    @media ${device.laptop} {
      font-size: 4.375rem;
      line-height: 5.25rem;
    }

    margin-bottom: 1.75rem;
    span {
      font-family: 'Fraunces';
      font-style: italic;
    }
    &.alternate {
      margin: 12.313rem 0 1.75rem;
      font-family: 'Fraunces';
      font-weight: 600;
      font-size: 3.25rem;
      line-height: 3.75rem;
      font-style: normal;
    }
  }
  p {
    margin: 0 0 1.75rem 0;
    line-height: 2rem;
  }
  h4 {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-top: 0;
    margin-bottom: 1.125rem;
    span {
      font-family: 'Fraunces';
      font-style: italic;
    }
  }
  .centered-paragraph {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media ${device.laptop} {
      width: 635px;
      margin: 0 auto 0 5.938rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }
    a,
    button {
      display: flex;
      align-self: start;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
        transition: all 0.5s ease-in;
      }
      span {
        display: block;
        margin-right: 0.625rem;
        white-space: nowrap;
        //line-height: 1.125rem;
      }
      &:hover {
        img {
          filter: none;
        }
      }
    }
  }
  .job-container {
    margin: 0 auto;
    .title {
      color: var(--lightGreen);
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 2.25rem;
      &.alternate {
        color: var(--white);
        font-weight: 600;
      }
      @media ${device.laptop} {
        font-size: 2.125rem;
        line-height: 3rem;
      }
    }
    a,
    button {
      display: flex;
      align-self: start;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
        transition: all 0.5s ease-in;
      }
      span {
        display: block;
        margin-right: 0.625rem;
        white-space: nowrap;
        //line-height: 1.125rem;
      }
      &:hover {
        img {
          filter: none;
        }
      }
    }
    table td {
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
`;

const Hero = forwardRef<HTMLDivElement, HeroProps>(({ children }, ref) => (
  <HeroStyles>
    <div ref={ref}>{children}</div>
  </HeroStyles>
));

Hero.displayName = 'Hero';

export default Hero;
