import CountUp from 'react-countup';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const StatisticStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0;
`;

interface StatisticProps {
  data: {
    count: number;
    icon: string;
    title: string;
  };
  className: string;
}

const Statistic = ({ data, className }: StatisticProps) => (
  <StatisticStyles className={className}>
    <img src={data.icon} alt={`${data.count.toLocaleString()} ${data.title}`} />

    <div className="count">
      <CountUp end={data.count} duration={2.5} separator="," useEasing={true}>
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <span ref={countUpRef} />
          </VisibilitySensor>
        )}
      </CountUp>
    </div>
    <div className="title">{data.title}</div>
  </StatisticStyles>
);
export default Statistic;
