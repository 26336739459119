import { forwardRef } from 'react';
import styled from 'styled-components';

import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';

type PageSectionProps = {
  children: JSX.Element | Array<JSX.Element>;
  fullscreen?: boolean;
  reverse?: boolean;
  noPadLeft?: boolean;
  noPadRight?: boolean;
  noPadTop?: boolean;
  noPadBottom?: boolean;
};

const PageSectionStyles = styled(BaseSectionStyles)<{
  fullscreen: boolean;
  noPadLeft: boolean;
  noPadRight: boolean;
  noPadTop: boolean;
  noPadBottom: boolean;
  reverse: boolean;
}>`
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  ${(props) => (props.fullscreen ? 'min-height: 100vh;' : '')}
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${(props) => (props.noPadTop ? 'padding-top: 0;' : 'padding-top: 6.25rem;')}
    ${(props) =>
      props.noPadBottom ? 'padding-bottom: 0;' : 'padding-bottom: 6.25rem;'}
    flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
    ${(props) => (props.noPadLeft ? 'padding-left: 0;' : '')}
    ${(props) => (props.noPadRight ? 'padding-right: 0;' : '')}
  }
  .article-image {
    position: relative;
    .credits {
      position: absolute;
      display: flex;
      opacity: 0;
      text-align: center;
      padding: 6rem 5rem;
      background: linear-gradient(
        180deg,
        rgba(37, 32, 57, 0) 50%,
        rgba(37, 32, 57, 1) 100%
      );
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      font-family: 'Fraunces';
      font-style: italic;
      font-weight: 600;
      font-size: 2.5rem;
      align-items: flex-end;
      transition: all 0.5s ease-in;
    }
    &:hover {
      .credits {
        opacity: 1;
      }
    }
  }
  img {
    width: 100%;
    height: auto;
    @media ${device.laptop} {
      width: auto;
    }
  }
  .section-content {
    padding: 1.5rem 0;
    @media ${device.laptop} {
      padding: 2.5rem 0;
      max-width: 444px;
    }
  }
  .call-to-action {
    margin: 0 auto;
    width: 100%;
    @media ${device.laptop} {
      margin: 0 auto 0 5.938rem;
      max-width: 920px;
    }
    h2 {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 2.5rem;
      @media ${device.laptop} {
        font-size: 2.125rem;
        line-height: 3rem;
      }
      span {
        font-family: 'Fraunces';
        font-style: italic;
        font-weight: 400;
      }
    }
    p {
      width: 100%;
      @media ${device.laptop} {
        width: 635px;
      }
      line-height: 2rem;
    }
  }
  h3 {
    max-width: 723px;
    text-align: center;
    margin: 0 auto;
  }
  h3,
  .title {
    color: var(--lightGreen);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.25rem;
    &.alternate {
      color: var(--white);
      font-weight: 600;
    }
    @media ${device.laptop} {
      font-size: 2.125rem;
      line-height: 3rem;
    }
  }
  h4 {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-top: 0;
    margin-bottom: 1.125rem;
    span {
      font-family: 'Fraunces';
      font-style: italic;
    }
  }
  .centered-paragraph {
    width: 100%;
    @media ${device.laptop} {
      width: 635px;
      margin: 0 auto 0 5.938rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
  .copy {
    @media ${device.laptop} {
      margin: 1.75rem 0;
    }
    line-height: 1.75rem;
    margin: 0.75rem 0;
  }
  button {
    flex-grow: 0;
  }
  button + button,
  a + button,
  button + a,
  a + a {
    margin-left: 0.5rem;
  }
  @media ${device.mobileM} {
    button + button {
      margin-left: 1.25rem;
    }
  }
  .padded-grid {
    list-style: none;
    padding: 0;
    margin: 0;
    width: auto;
    @media ${device.laptop} {
      display: flex;
      flex-wrap: wrap;
      width: 650px;
    }

    li {
      width: 100%;
      margin: 0 auto;
      padding: 0 1rem;
      @media ${device.laptop} {
        width: 260px;
        &:nth-child(odd) {
          margin-right: 7.813rem;
        }
      }

      span,
      img,
      p {
        display: block;
      }
      span {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 2.25rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.75rem;
      }
      img {
        width: 169px;
        height: 140px;
        margin: 0 auto;
        @media ${device.laptop} {
          width: 179px;
          height: 150px;
        }
      }
    }
  }
  .profile-grid {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 2rem;
    @media ${device.laptop} {
      margin-top: 3.75rem;
    }
    li {
      width: 165px;
      margin-bottom: 1.25rem;
      @media ${device.laptop} {
        width: 198px;
        margin-bottom: 3.125rem;
      }
      span {
        display: block;
      }
      button {
        background: none;
        border: none;
        color: inherit;
      }
      .profile-image {
        overflow: hidden;
        img {
          display: block;
          transition-property: transform;
          transition-duration: 0.8s;
          transition-timing-function: ease-in-out;
          transition-delay: 0s;
        }
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
      .profile-name {
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.75rem;
      }
      .profile-position {
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }
  }
`;

const PageSection = forwardRef<HTMLElement, PageSectionProps>(
  (
    {
      children,
      fullscreen = true,
      noPadLeft = false,
      noPadRight = false,
      noPadTop = false,
      noPadBottom = false,
      reverse = false,
    },
    ref
  ) => {
    return (
      <PageSectionStyles
        fullscreen={fullscreen}
        noPadLeft={noPadLeft}
        noPadRight={noPadRight}
        noPadTop={noPadTop}
        noPadBottom={noPadBottom}
        reverse={reverse}
        ref={ref}
      >
        {children}
      </PageSectionStyles>
    );
  }
);

PageSection.displayName = 'PageSection';

export default PageSection;
