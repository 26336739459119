/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */
function debounce(func: any, wait: number, immediate?: boolean) {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: any) {
    const context = this,
      args = arguments;
    const later = function () {
      //timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default debounce;
