import { CSSTransition } from 'react-transition-group';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Partner } from '../types';
import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';
import PartnersData from '../data/partner-data.json';

type PartnerCarouselProps = {
  children?: Array<JSX.Element> | JSX.Element;
};

const PartnersCarouselStyles = styled(BaseSectionStyles)`
  padding: 5rem 0;
  background-color: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  position: absolute;
  bottom: 686px;
  @media ${device.laptop} {
    bottom: 549px;
  }
  left: 0;
  right: 0;
  @media ${device.laptop} {
    padding: 4.75rem 0;
    margin-top: 0;
  }
  h3 {
    font-size: 2.125rem;
    font-weight: 500;
    line-height: 3rem;
    margin: 0;
  }
  .carousel {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    @media ${device.tablet} {
      width: 1004px;
      min-height: 131px;
    }
    min-height: 315px;
    margin: 3.125rem 0 2.5rem;
    a:hover {
      img {
        opacity: 0.5;
      }
    }
    img {
      width: 152px;
      height: 104px;
      @media ${device.laptop} {
        width: 197px;
        height: auto;
      }
      opacity: 0;
      transition: opacity 1s ease;
    }
    img.active {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }
  .carousel-controls {
    display: flex;
    min-height: 10px;
    align-items: flex-end;
  }
  button {
    background: none;
    margin: 0;
    padding: 0;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    div {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background: #8c8c8c;
      border: 1px solid #8c8c8c;
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0.2;
    }
    &.active {
      border: 1px solid var(--lightGreen);
      div {
        background: var(--lightGreen);
        border: 1px solid var(--lightGreen);
        opacity: 1;
      }
    }

    &:hover,
    &.active {
      //border-bottom: 10px solid var(--lightGreen);
    }
  }
  button + button {
    margin-left: 1rem;
  }
  .slide {
    position: absolute;
    width: 316px;
    @media ${device.tablet} {
      width: auto;
    }
  }
  .slide-enter {
    opacity: 0;
  }

  .slide-enter-active {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 600ms;
  }

  .slide-exit {
    opacity: 1;
  }

  .slide-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
`;

const PartnersCarousel = forwardRef<HTMLDivElement, PartnerCarouselProps>(
  ({}, ref) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const visibleItems = 5;
    const totalSlides = Math.ceil(PartnersData.length / visibleItems);

    useEffect(() => {
      const timer = setInterval(gotoNextSlide, 5000);
      return () => clearInterval(timer);
    }, [activeIndex]);

    const gotoNextSlide = () => {
      if (activeIndex < totalSlides - 1) {
        setActiveIndex((index) => index + 1);
      } else {
        setActiveIndex(0);
      }
    };

    const renderCarouselControls = () => {
      let controls: Array<JSX.Element> = [];
      for (let i = 0; i < totalSlides; i++) {
        controls = [
          ...controls,
          <button
            data-slide={i}
            key={i}
            className={activeIndex === i ? 'active' : ''}
            onClick={onControlClick}
            aria-label={activeIndex === i ? 'Active slide' : 'Next slide'}
          >
            <div></div>
          </button>,
        ];
      }
      return controls;
    };

    const onControlClick = (event: React.MouseEvent) => {
      setActiveIndex(
        Number(event.currentTarget.attributes.getNamedItem('data-slide')?.value)
      );
    };

    const renderSlides = () => {
      const slides = [];
      for (let i = 0; i < totalSlides; i++) {
        slides.push(
          <CSSTransition
            in={activeIndex === i}
            key={i}
            classNames="slide"
            timeout={1000}
            unmountOnExit
          >
            <div className="slide">
              {PartnersData.map((partner: Partner, index) => {
                const startPos = visibleItems * i;
                if (index >= startPos && index < startPos + visibleItems) {
                  return (
                    <a
                      key={index}
                      href={partner.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        key={partner.id}
                        src={`images/${partner.src}.png`}
                        srcSet={`images/${partner.src}@2x.png 2x, images/${partner.src}@3x.png 3x`}
                        alt={partner.alt}
                        className="active"
                      />
                    </a>
                  );
                }
              })}
            </div>
          </CSSTransition>
        );
      }
      return slides;
    };

    return (
      <PartnersCarouselStyles ref={ref}>
        <h3>Our partners</h3>
        <div className="carousel">{renderSlides()}</div>
        <div className="carousel-controls">{renderCarouselControls()}</div>
      </PartnersCarouselStyles>
    );
  }
);

PartnersCarousel.displayName = 'PartnersCarousel';

export default PartnersCarousel;
