import gsap from 'gsap';
import { forwardRef, useEffect } from 'react';
import styled from 'styled-components';

import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';

type VerticalCarouselProps = {
  children?: Array<JSX.Element> | JSX.Element;
  data: Array<{ id: number; src: string; title: string; copy: string }>;
};

const VerticalCarouselStyles = styled(BaseSectionStyles)`
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  position: relative;
  //border: 1px solid white;
  height: 900px;
  @media ${device.laptop} {
    padding-top: 6.25rem;
    padding-bottom: 6.25em;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  h3 {
    max-width: 723px;
    text-align: center;
    margin: 0 auto;
    color: var(--white);
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.25rem;
    @media ${device.laptop} {
      font-size: 2.125rem;
      line-height: 3rem;
    }
  }
  .carousel {
    display: flex;
    .depth-guage {
      width: 165px;
      height: 1000px;
      padding-top: 250px;
      svg {
        margin: auto;
      }
      svg line {
        stroke-width: 1;
        stroke: white;
        &:nth-child(5n -8) {
          stroke: var(--lightGreen);
        }
      }
    }
    .slide {
      @media ${device.laptop} {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        position: absolute;
        opacity: 0;
        top: 300px;
        height: 700px;
      }
      img {
        width: 100%;
        height: auto;
        @media ${device.laptop} {
          width: auto;
        }
      }
      .section-content {
        padding: 1.5rem 0;
        @media ${device.laptop} {
          padding: 2.5rem 0;
          max-width: 444px;
          margin-right: 7.875rem;
        }
        .title {
          color: var(--lightGreen);
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 2.25rem;
          @media ${device.laptop} {
            font-size: 2.125rem;
            line-height: 3rem;
          }
        }
        .copy {
          line-height: 1.75rem;
          margin: 0.75rem 0;
          white-space: pre-wrap;
          @media ${device.laptop} {
            margin: 1.75rem 0;
          }
        }
      }
    }
  }
`;

const VerticalCarousel = forwardRef<HTMLDivElement, VerticalCarouselProps>(
  ({ data }, ref) => {
    useEffect(() => {
      const svgns = 'http://www.w3.org/2000/svg';
      const demo = document.querySelector('svg');

      const positions = data.length - 1; // how many numbers
      let startY = 8; // first line position and fist number position
      const x2Pos = 20; // bottom of each tick line
      let x1Pos;
      const spacing = 20; // space between lines

      // creates the line elements
      const makeLine = (xp: number) => {
        const newLine = document.createElementNS(svgns, 'line');
        demo!.appendChild(newLine);
        gsap.set(newLine, {
          attr: { x1: xp, x2: x2Pos, y1: startY, y2: startY },
        });
      };

      for (let j = 0; j < positions; j++) {
        for (let i = 0; i < 5; i++) {
          x1Pos = i === 0 ? x2Pos + 20 : x2Pos + 10; // first line in each pack is slightly longer
          makeLine(x1Pos);
          startY += spacing;
        }
      }
      makeLine(x2Pos + 20); //  need 1 extra line for the last
    }, []);

    return (
      <VerticalCarouselStyles ref={ref}>
        <h3 className="alternate animate-reveal">How to use ReefCloud</h3>
        <div className="carousel animate-reveal">
          <div className="depth-guage">
            <svg
              id="guage"
              width="165"
              height="500"
              viewBox="0 0 165 500"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="slider"
                d="M10 6.49982L3.85426e-07 13L9.53674e-07 -4.37114e-07L10 6.49982Z"
                fill="#00FFC2"
              />
            </svg>
          </div>
          <div className="slides">
            {data.map((item) => (
              <div key={item.id} className="slide">
                <img
                  src={`images/${item.src}.png`}
                  srcSet={`images/${item.src}@2x.png 2x, images/${item.src}@3x.png 3x`}
                  alt={item.title}
                />
                <div className="section-content">
                  <span className="title alternate">{item.title}</span>
                  <p className="copy">{item.copy}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </VerticalCarouselStyles>
    );
  }
);

VerticalCarousel.displayName = 'VerticalCarousel';

export default VerticalCarousel;
