import MailchimpSubscribe from 'react-mailchimp-subscribe';

import CloseButton from './CloseButton';
import ModalStyles from '../styles/ModalStyles';
import SubscribeForm from './SubscribeForm';

const mailchimpUrl = process.env.REACT_APP_MAILCHIMP_URL || '';
interface SubscribeModalProps {
  onCloseHandler: () => void;
}

const SubscribeModal = ({ onCloseHandler }: SubscribeModalProps) => {
  return (
    <ModalStyles>
      <CloseButton onClick={onCloseHandler} />
      <MailchimpSubscribe
        url={mailchimpUrl}
        render={({ subscribe, status, message }) => (
          <SubscribeForm
            message={message}
            status={status}
            onValidated={(formData) => subscribe(formData)}
            closeModalHandler={onCloseHandler}
          />
        )}
      />
    </ModalStyles>
  );
};

export default SubscribeModal;
