import { CSSTransition } from 'react-transition-group';
import { useState } from 'react';
import styled from 'styled-components';

import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';

import AngleLeftSolid from '../images/angle-left-solid.svg';
import AngleRightSolid from '../images/angle-right-solid.svg';
import AustraliaImage from '../images/australia.svg';
import FijiImage from '../images/fiji.svg';

const enum Location {
  Australia = 'AUSTRALIA',
  Fiji = 'FIJI',
}

const QUOTES = [
  {
    quote:
      'ReefCloud makes knowledge readily available and useful. Working togther as a monitoring \
      community and sharing our knowledge and data is much more efficient and effective in ensuring \
      the long-term sustainability of our coral reef ecosystems.',
    quoter:
      'Dr Manuel Gonzalez Rivero, Research Scientist,\nAustralian Institute of Marine Science',
    location: Location.Australia,
  },
  {
    quote:
      'ReefCloud saves you a lot of time annotating every single image that you upload on a platform. \
      So, the minute you upload photos and you have trained the machine enough, you can totally trust \
      ReefCloud on identifying images and get instant results.',
    quoter:
      'Yashika Nand, Science Coordinator, \n Wildlife Conservation Society, Fiji',
    location: Location.Fiji,
  },
  {
    quote:
      'ReefCloud helps the GCRMN, managers and decision makers by standardising coral reef monitoring data and making \
      them available much faster so we can make informed and timely decisions about the conservation and \
      sustainable use of coral reefs.',
    quoter:
      'Dr David Souter, Chief Research Officer, Australian Institute of Marine Science, \
      Global Coordinator, Global Coral Reef Monitoring Network',
    location: Location.Australia,
  },
];

const QuotesCarouselStyles = styled(BaseSectionStyles)`
  padding: 6.25rem var(--gutters);
  position: relative;
  height: 800px;
  overflow: hidden;
  @media ${device.laptop} {
    overflow: visible;
  }
  .controls {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    li {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 32px;
    }
    li + li {
      margin-left: 0.625rem;
    }
  }
  .slide {
    > img {
      position: absolute;
      top: -120px;
      left: -180px;
      opacity: 0.3;
      width: 200%;
      @media ${device.laptop} {
        top: -460px;
        left: -360px;
        width: auto;
      }
    }
    .carousel-item {
      position: absolute;

      @media ${device.laptop} {
        width: 810px;
        margin: 0 auto 0 auto;
      }
      @media ${device.laptopL} {
        margin: 0 15.625rem 0 22.188rem;
      }
      span {
        font-family: 'Fraunces';
        color: var(--lightGreen);
        display: block;
        font-size: 1.5rem;
        line-height: 2.25rem;
        @media ${device.laptop} {
          font-size: 3rem;
          line-height: 3.875rem;
        }
      }
      sub {
        display: block;
        font-size: 1rem;
        line-height: 2rem;
        margin: 1.5rem 0;
        @media ${device.laptop} {
          white-space: pre-line;
        }
      }
    }
  }
  .slide-enter {
    opacity: 0;
  }

  .slide-enter-active {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 600ms;
  }

  .slide-exit {
    opacity: 1;
  }

  .slide-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
`;

const CarouselButtonStyles = styled.button`
  background: none;
  border: none;
`;

const QuotesCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextSlide = () => {
    const newIndex = activeIndex + 1 > QUOTES.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
  };
  const prevSlide = () => {
    const newIndex = activeIndex - 1 < 0 ? QUOTES.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
  };
  return (
    <QuotesCarouselStyles>
      {QUOTES.map((item, index) => {
        const { quote, quoter, location } = item;
        return (
          <CSSTransition
            in={index === activeIndex}
            key={index}
            classNames="slide"
            timeout={1000}
            unmountOnExit
          >
            <div className="slide">
              <img
                src={location == Location.Fiji ? FijiImage : AustraliaImage}
                alt="Map of Australia"
              />
              <div key={index} className="carousel-item">
                <span>&ldquo;{quote}&rdquo;</span>
                <sub>{quoter}</sub>
                <ul className="controls">
                  <li onClick={prevSlide}>
                    <CarouselButtonStyles aria-label="Previous">
                      <img src={AngleLeftSolid} alt="" />
                    </CarouselButtonStyles>
                  </li>
                  <li onClick={nextSlide}>
                    <CarouselButtonStyles aria-label="Next">
                      <img src={AngleRightSolid} alt="" />
                    </CarouselButtonStyles>
                  </li>
                </ul>
              </div>
            </div>
          </CSSTransition>
        );
      })}
    </QuotesCarouselStyles>
  );
};

export default QuotesCarousel;
