import { useState } from 'react';
import styled from 'styled-components';

interface HamburgerProps {
  onClick: () => void;
}

const HamburgerStyles = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  align-self: center;
  .line {
    width: 18px;
    height: 2px;
    display: block;
    margin: 0.25rem auto;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    cursor: pointer;
  }

  &.is-active .line:nth-child(2) {
    opacity: 0;
  }

  &.is-active .line:nth-child(1) {
    transform: translateY(6px) rotate(-45deg);
  }

  &.is-active .line:nth-child(3) {
    transform: translateY(-6px) rotate(45deg);
  }
`;

const Hamburger = ({ onClick }: HamburgerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = (e: any) => {
    const el = e.currentTarget;
    if (isOpen) {
      el!.classList.remove('is-active');
    } else {
      el!.classList.add('is-active');
    }

    setIsOpen(!isOpen);

    if (onClick) {
      onClick();
    }
  };
  return (
    <HamburgerStyles onClick={handleClick}>
      <span className="line" />
      <span className="line" />
      <span className="line" />
    </HamburgerStyles>
  );
};

export default Hamburger;
