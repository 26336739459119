import { forwardRef } from 'react';
import styled from 'styled-components';

import BaseSectionStyles from '../styles/BaseSectionStyles';
import device from '../utils/device';
import Statistic from './Statistic';
import StatsDataIcon from '../images/stats-data-icon.svg';
import StatsMonitoringIcon from '../images/stats-monitoring-icon.svg';
import StatsPhotosIcon from '../images/stats-photos-icon.svg';
import CountriesIcon from '../images/country-icon.svg';
import UsersIcon from '../images/user-icon.svg';

const STATISTICS = [
  {
    id: 'monitoring_sites',
    icon: StatsMonitoringIcon,
    count: 2608,
    title: 'Monitoring sites',
  },
  {
    id: 'photos_taken',
    icon: StatsPhotosIcon,
    count: 1116899,
    title: 'Photos taken',
  },
  {
    id: 'contributors',
    icon: StatsDataIcon,
    count: 67,
    title: 'Data contributors',
  },
  {
    id: 'countries',
    icon: CountriesIcon,
    count: 67,
    title: 'Countries',
  },
  {
    id: 'users',
    icon: UsersIcon,
    count: 200,
    title: 'Users',
  },
];

const StatsSectionStyles = styled(BaseSectionStyles)`
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .count {
    font-family: 'Fraunces';
    font-size: 4.375rem;
    margin-top: 0.5rem;
    margin-bottom: 1.75rem;
  }
  .title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--lightGreen);
  }
`;

type StatsSectionProps = {
  children?: React.ReactNode;
  stats: {
    [key: string]: string;
  };
};

const StatsSection = forwardRef<HTMLElement, StatsSectionProps>(
  (props, ref) => {
    const updatedStats = STATISTICS.map((item) => {
      return { ...item, count: Number(props.stats[item.id]) };
    });
    return (
      <StatsSectionStyles ref={ref}>
        {updatedStats.map((stat, index) => (
          <Statistic key={index} className="animate-reveal" data={stat} />
        ))}
      </StatsSectionStyles>
    );
  }
);

StatsSection.displayName = 'StatsSection';

export default StatsSection;
