import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Spacer from '../components/Spacer';
import useGaTracker from '../components/useGaTracker';

const ContactPageStyles = styled.section`
  width: 350px;
  margin: 12.25rem auto;
  h1 {
    font-size: 2.125rem;
    line-height: 3rem;
    font-weight: 500;
  }
  iframe {
    border: none;
  }
`;

const Contact = () => {
  useGaTracker('Contact');
  return (
    <>
      <Helmet>
        <title>ReefCloud - Contact</title>
      </Helmet>
      <ContactPageStyles>
        <h1>Contact us</h1>
        <iframe
          src="https://www.cognitoforms.com/f/j2bxKw7jhUSGJZFEeGFJsQ/9"
          height="497"
        ></iframe>
      </ContactPageStyles>
      <Spacer height={100} />
    </>
  );
};

export default Contact;
