import { css } from '@emotion/react';
import { useState } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import styled from 'styled-components';

import Button from './Button';
import InputField from './InputField';

const SubscribeFormStyles = styled.div`
  width: 360px;
  margin: 0 auto;
  text-align: center;
  div {
    margin-bottom: 1rem;
  }
  button {
    margin-top: 1rem;
  }
  h2 {
    font-size: 2.125rem;
    font-weight: 500;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      text-align: center;
      label {
        margin-bottom: 0;
      }
    }
  }
`;

interface SubscribeFormProps {
  message: string | Error | null;
  status: string | null;
  onValidated: (formData: any) => void;
  closeModalHandler: () => void;
}

const SubscribeForm = ({
  message,
  status,
  onValidated,
  closeModalHandler,
}: SubscribeFormProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    email &&
      name &&
      email.lastIndexOf('@') > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: name,
      });
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const formFields = () => (
    <>
      <li>
        <InputField
          onChangeHandler={setName}
          type="text"
          value={name}
          placeholder="Enter your first name"
          isRequired
          name="name"
        />
      </li>
      <li>
        <InputField
          onChangeHandler={setEmail}
          type="email"
          value={email}
          placeholder="Enter your email address"
          isRequired
          name="name"
        />
      </li>
    </>
  );

  const renderView = (state: string | null) => {
    switch (state) {
      case 'sending':
        return (
          <>
            <h2>Subscribe with us</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
              <ul>
                {formFields()}
                <li>
                  <FadeLoader
                    color={'#00FFC2'}
                    css={override}
                    loading={true}
                    height={8}
                    width={8}
                    radius={16}
                    margin={0}
                  />
                </li>
              </ul>
            </form>
          </>
        );
      case 'error':
        return (
          <>
            <h2>Oops, looks like something went wrong</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: message
                  ? message.toString()
                  : 'An error occured, please try again.',
              }}
            />
            <form onSubmit={(e) => handleSubmit(e)}>
              <ul>
                {formFields()}
                <li>
                  <InputField
                    label="Try again"
                    type="submit"
                    formValues={[email, name]}
                  />
                </li>
              </ul>
            </form>
          </>
        );
      case 'success':
        return (
          <>
            <h2>You&apos;re all subscribed</h2>
            <div>Thanks for subscribing with us</div>
            <Button primary onClick={closeModalHandler}>
              Close
            </Button>
          </>
        );
      default:
        return (
          <>
            <h2>Subscribe with us</h2>
            <form onSubmit={(e) => handleSubmit(e)}>
              <ul>
                {formFields()}
                <li>
                  <InputField
                    label="Submit"
                    type="submit"
                    formValues={[email, name]}
                  />
                </li>
              </ul>
            </form>
          </>
        );
    }
  };
  return <SubscribeFormStyles>{renderView(status)}</SubscribeFormStyles>;
};

export default SubscribeForm;
