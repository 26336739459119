import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';

import { animateSection } from '../utils/animation';
import Button from '../components/Button';
import CloseButton from '../components/CloseButton';
import Hero from '../components/Hero';
import ProfileModalStyles from '../styles/ProfileModalStyles';
import PageSection from '../components/PageSection';
import PartnersSection from '../components/PartnersSection';
import ProfilesData from '../data/profiles-data.json';
import SectionTitleStyles from '../styles/SectionTitleStyles';

import ValuesImage1 from '../images/values-image-1.svg';
import ValuesImage2 from '../images/values-image-2.svg';
import ValuesImage3 from '../images/values-image-3.svg';
import ValuesImage4 from '../images/values-image-4.svg';
import loginIcon from '../images/siteLoginMove.svg';
import useGaTracker from '../components/useGaTracker';
import { Helmet } from 'react-helmet';

const VALUES_DATA = [
  {
    id: 1,
    src: ValuesImage1,
    title: 'Impactful evidence',
    copy: 'We help bridge the gap between scientific advice & reef management decision making',
  },
  {
    id: 2,
    src: ValuesImage2,
    title: 'Purposeful collaboration',
    copy: 'We create greater impact by working together to protect our reefs',
  },
  {
    id: 3,
    src: ValuesImage3,
    title: 'Integrative intelligence',
    copy: 'We solve problems more effectively by leveraging the power of science & technology',
  },
  {
    id: 4,
    src: ValuesImage4,
    title: 'Knowledge sharing',
    copy: 'We make data sharable, technologies compatible & information more widely available',
  },
];

const About = () => {
  useGaTracker('About');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalProfileId, setModalProfileId] = useState(0);

  const heroRef = useRef(null);
  const sectionOneRef = useRef(null);
  const valuesRef = useRef(null);
  const profilesRef = useRef(null);
  const lastSection = useRef(null);

  const history = useHistory();

  useEffect(() => {
    const elements = [
      heroRef.current,
      sectionOneRef.current,
      valuesRef.current,
      profilesRef.current,
      lastSection.current,
    ];

    elements.forEach((el, index) => {
      const delay = index === 0 ? 1.5 : index === 1 ? 2.25 : 0;
      const animateOnScroll = index === 0 ? false : index === 1 ? false : true;
      animateSection(el!, animateOnScroll, delay);
    });

    // Partners scroll trigger
    const partnersTL = gsap.timeline({
      scrollTrigger: {
        scroller: '.container',
        pin: true,
        pinType: 'fixed',
        pinReparent: true,
        start: 'top top',
        end: '+=450%',
        //scrub: true,
        trigger: elements[elements.length - 1],
      },
    });

    return function cleanup() {
      partnersTL.kill();
      elements.forEach((el) => gsap.killTweensOf(el));
    };
  }, []);

  const selectedProfile = ProfilesData.find(
    (profile) => profile.id === modalProfileId.toString()
  );

  Modal.setAppElement('#root');

  const openModal = (id: number) => {
    setModalIsOpen(true);
    setModalProfileId(id);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const parseHtml = (str?: string) => {
    return { __html: str || '' };
  };

  return (
    <>
      <Helmet>
        <title>ReefCloud - About</title>
      </Helmet>
      <Hero ref={heroRef}>
        <h1 className="alternate animate-reveal">
          How can AI help protect coral reefs?
        </h1>
        <p className="animate-reveal">
          About 70% of the world&apos;s coral reefs are currently monitored but
          it can take years to bring all the available data together,
          synthesising disparate formats and data collection methods.
        </p>
        <p className="animate-reveal">
          ReefCloud is an open-access platform designed to quickly and
          efficiently collate and analyse data to improve decision-making and
          inform conservation across the world.
        </p>
      </Hero>
      <Hero ref={sectionOneRef}>
        <div className="centered-paragraph">
          <h4 className="animate-reveal">
            <span>Our mission</span> is to advance coral reef health and help
            sustain the blue economies, societies and cultures across the world
            that depend on them.
          </h4>
          <p className="animate-reveal">
            Co-funded by the Australian Department of Foreign Affairs and Trade
            and the Australian Institute of Marine Science (AIMS), ReefCloud
            provides an end-to-end solution for integrating, synthesising,
            reporting and communicating coral reef monitoring using technology
            developed at AIMS.
          </p>
          <Button
            href="https://www.aims.gov.au/about-aims"
            className="animate-reveal"
          >
            <span>Find out about AIMS</span>
            <img src={loginIcon} alt="External link" />
          </Button>
        </div>
      </Hero>
      <PageSection ref={valuesRef}>
        <SectionTitleStyles className="animate-reveal">
          Our values
        </SectionTitleStyles>
        <ul className="padded-grid">
          {VALUES_DATA.map((item) => (
            <li key={item.id} className="animate-reveal">
              <img src={item.src} alt={`${item.title} icon`} />
              <span>{item.title}</span>
              <p>{item.copy}</p>
            </li>
          ))}
        </ul>
      </PageSection>
      <PageSection ref={profilesRef}>
        <div>
          <SectionTitleStyles className="animate-reveal">
            Meet our team
          </SectionTitleStyles>
          <SectionTitleStyles className="animate-reveal" small>
            AIMS Team
          </SectionTitleStyles>

          <ul className="profile-grid">
            {ProfilesData.filter((item) => item.aims === true).map((item) => (
              <li key={item.id} className="animate-reveal">
                <div onClick={() => openModal(Number(item.id))}>
                  <div
                    className="profile-image"
                    role="button"
                    aria-pressed="false"
                  >
                    <img
                      src={
                        item.src !== ''
                          ? `images/profiles/${item.src}.png`
                          : 'images/profiles/profile-male.svg'
                      }
                      alt={`Profile picture of ${item.name}`}
                    />
                  </div>
                  <span className="profile-name">
                    <a target="_blank">{item.name}</a>
                  </span>
                  <span className="profile-position">
                    <a target="_blank">{item.position}</a>
                  </span>
                </div>
              </li>
            ))}
            <li></li>
          </ul>
          <SectionTitleStyles className="animate-reveal" small>
            Key Collaborators
          </SectionTitleStyles>
          <ul className="profile-grid">
            {ProfilesData.filter((item) => item.aims === false).map((item) => (
              <li key={item.id} className="animate-reveal">
                <div
                  className="profile-image"
                  onClick={() => openModal(Number(item.id))}
                  role="button"
                  aria-pressed="false"
                >
                  <img
                    src={
                      item.src !== ''
                        ? `images/profiles/${item.src}.png`
                        : 'images/profiles/profile-male.svg'
                    }
                    alt={`Profile picture of ${item.name}`}
                  />
                </div>
                <span className="profile-name">{item.name}</span>
                <span className="profile-position">{item.position}</span>
              </li>
            ))}
            <li></li>
          </ul>
          <Modal
            closeTimeoutMS={200}
            contentLabel="modal"
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <ProfileModalStyles>
              <CloseButton onClick={closeModal} />
              <div className="profile-image">
                <img
                  src={
                    selectedProfile?.src !== ''
                      ? `images/profiles/${selectedProfile?.src}@2x.png`
                      : 'images/profiles/profile-male.svg'
                  }
                  alt={`Profile picture of ${selectedProfile?.name}`}
                />
              </div>
              <div className="profile-content">
                <span className="profile-name">{selectedProfile?.name}</span>
                <span className="profile-position">
                  {selectedProfile?.position}
                </span>
                <span className="profile-org">{selectedProfile?.org}</span>
                <span
                  className="profile-bio"
                  dangerouslySetInnerHTML={parseHtml(selectedProfile?.bio)}
                ></span>
              </div>
            </ProfileModalStyles>
          </Modal>
        </div>
      </PageSection>
      <PageSection ref={lastSection}>
        <div className="call-to-action">
          <SectionTitleStyles className="animate-reveal">
            Partner with us
          </SectionTitleStyles>
          <p className="animate-reveal">
            With the help and support of people, corporations and governments
            all over the world, we aim to realise our mission and work towards
            solving key challenges faced by our reefs. If you’re interested in
            joining our efforts and become a partner, please get in touch!
          </p>
          <Button
            className="animate-reveal"
            onClick={() => {
              history.push('/contact');
            }}
          >
            Contact us
          </Button>
        </div>
      </PageSection>

      <PartnersSection />
    </>
  );
};

export default About;
