import p5 from 'p5';
import * as PIXI from 'pixi.js';

class Particle {
  x: number;
  y: number;
  xSpeed: number;
  ySpeed: number;
  canvas_width: number;
  canvas_height: number;
  grad: PIXI.Sprite;
  constructor(x: number, y: number, texture: any, size = 1500, alpha = 1) {
    this.x = x;
    this.y = y;
    this.xSpeed = p5.prototype.random(-0.5, 0.5);
    this.ySpeed = p5.prototype.random(-0.5, 0.5);
    this.canvas_width = size;
    this.canvas_height = size;
    this.grad = new PIXI.Sprite(texture);
    this.grad.rotation = 0;
    this.grad.alpha = alpha;
    this.grad.width = this.canvas_width;
    this.grad.height = this.canvas_height;
    this.grad.anchor.x = 0.5;
    this.grad.anchor.y = 0.5;
    // this.grad.direction = Math.random() * Math.PI * 2;
    // this.grad.turningSpeed = Math.random() - 0.0000001;
    // this.grad.speed = 0.05 + Math.random() * 2;
    // this.grad.x_amplitude = Math.random() * 1.618;
    // this.grad.y_amplitude = Math.floor(Math.random() * 1);
    this.update();
  }
  update() {
    if (this.x < -500 || this.x > window.innerWidth) {
      this.xSpeed *= -1;
    }
    if (this.y < -500 || this.y > window.innerHeight) {
      this.ySpeed *= -1;
    }
    this.x += this.xSpeed;
    this.y += this.ySpeed;
  }
}

export default Particle;
