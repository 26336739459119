import styled from 'styled-components';

import device from '../utils/device';

const ModalStyles = styled.div`
  @media ${device.laptop} {
    display: flex;
  }
  > button {
    position: fixed;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    @media ${device.laptop} {
      top: 40px;
      right: 40px;
    }
  }
`;

export default ModalStyles;
