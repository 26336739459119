import { useState } from 'react';
import styled from 'styled-components';
import React from 'react';

import ErrorIcon from '../images/error-icon.svg';

interface InputFieldProps {
  formValues?: Array<any>;
  label?: string;
  name?: string;
  onChangeHandler?: (e: any) => void;
  placeholder?: string;
  isRequired?: boolean;
  type: string;
  value?: string;
}

const InputFieldStyles = styled.label`
  display: block;
  margin-bottom: 1rem;
  font-weight: 600;
  .input-wrapper {
    position: relative;
  }
  input,
  select {
    height: 48px;
  }
  input,
  select,
  textarea {
    margin-bottom: 1.5rem;
    width: 100%;
    background: transparent;
    border: 1px solid #8c8c8c;
    border-radius: 5px;
    color: var(--white);
    padding: 1rem;
    font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
  }
  input {
    padding-right: 40px;
  }
  input[type='submit'] {
    padding: 0.9rem 1.25rem;
    border-radius: 2rem;
    font-size: 1rem;
    line-height: 1;
    height: 48px;
    display: inline-block;
    background-color: #00e5af;
    color: var(--darkPurple);
    width: auto;
    transition: all 0.5s ease-in;
    transition-property: color, background, border;
    &:hover {
      background-color: #00b588;
      color: var(--white);
      text-decoration: none;
    }
    &:disabled {
      background-color: #818181;
      color: var(--darkPurple);
      transition: none;
    }
  }
  img {
    display: none;
  }
  &.error {
    input,
    textarea {
      border: 1px solid red;
      color: red;
    }
    img {
      position: absolute;
      right: 15px;
      top: 17px;
      display: block;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #fff !important;
  }
`;

const InputField = ({
  formValues,
  label,
  name,
  onChangeHandler,
  placeholder,
  isRequired,
  type,
  value,
}: InputFieldProps) => {
  const [isError, setIsError] = useState(false);
  const validateText = (value: string | undefined) => {
    return value === '';
  };
  const validateEmail = (value: string | undefined) => {
    return value !== undefined && value.indexOf('@') === -1;
  };
  const validateInput = (value: string | undefined, type: string) => {
    let validated: boolean;
    switch (type) {
      case 'text':
        validated = validateText(value);
        setIsError(validated);
        return validated;
      case 'email':
        validated = validateEmail(value);
        setIsError(validated);
        return validated;
    }
  };
  const validateAll = (values: Array<any> | undefined = []) => {
    if (values.some((f) => f === '') || values[0].indexOf('@') === -1) {
      return true;
    } else {
      return false;
    }
  };
  const renderInputField = () => {
    if (type === 'submit') {
      return (
        <InputFieldStyles>
          <input
            className="submit"
            type="submit"
            value={label}
            disabled={validateAll(formValues)}
          />
        </InputFieldStyles>
      );
    } else if (type === 'textarea') {
      return (
        <InputFieldStyles className={isError ? 'error' : ''}>
          {label}
          <textarea
            onChange={(e) => onChangeHandler && onChangeHandler(e.target.value)}
            placeholder={placeholder}
            value={value}
            required={isRequired}
            onBlur={() => validateInput(value, 'text')}
            className="field"
            rows={7}
            name={name}
          />
        </InputFieldStyles>
      );
    } else {
      return (
        <InputFieldStyles className={isError ? 'error' : ''}>
          {label}
          <div className="input-wrapper">
            <input
              onChange={(e) =>
                onChangeHandler && onChangeHandler(e.target.value)
              }
              type={type}
              placeholder={placeholder}
              value={value}
              required={isRequired}
              onBlur={() => isRequired && validateInput(value, type)}
              className="field"
              name={name}
            />
            <img src={ErrorIcon} />
          </div>
        </InputFieldStyles>
      );
    }
  };
  return renderInputField();
};

export default React.memo(InputField);
