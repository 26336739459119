import styled from 'styled-components';

import device from '../utils/device';

const BaseSectionStyles = styled.section`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 var(--gutters);
  @media ${device.laptop} {
    padding: 0 var(--guttersLaptop);
  }
  @media ${device.laptopL} {
    padding: 0 var(--guttersLaptopL);
  }
`;

export default BaseSectionStyles;
