import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Button from './Button';
import debounce from '../utils/debounce';
import device from '../utils/device';
import Hamburger from '../components/Hamburger';
import logo from '../images/coreLogoName.svg';
import loginIcon from '../images/siteLoginMove.svg';
interface HeaderProps {
  children: JSX.Element | Array<JSX.Element>;
}

const HeaderStyles = styled.header<{ topValue: number; sticky: boolean }>`
  padding: 1.375rem var(--gutters);
  @media ${device.laptop} {
    padding: 1.375rem var(--guttersLaptop);
  }
  @media ${device.laptopL} {
    padding: 1.375rem var(--guttersLaptopL);
  }
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: ${(props) => (props.topValue === 0 ? 0 : `${props.topValue}px`)};
  transition: all 0.3s;
  transition-property: background top;
  z-index: 99;
  background: ${(props) =>
    props.sticky ? 'transparent' : 'var(--darkPurple)'};
  .logo {
    position: absolute;
    z-index: 10;
    display: flex;
    align-items: center;
    @media ${device.laptop} {
      position: static;
      right: 16px;
    }
  }
  > button {
    position: absolute;
    z-index: 10;
    right: 1.5rem;
    top: 1.25rem;
    @media ${device.laptop} {
      display: none;
    }
  }
  .menu {
    display: flex;
    align-items: center;
    a {
      img {
        transition: all 0.5s ease-in;
      }
      &:hover {
        img {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
            brightness(102%) contrast(102%);
        }
      }
    }
  }
`;

const LoginBtnStyles = styled.div`
  display: none;
  margin-left: 1rem;
  @media ${device.laptop} {
    display: flex;
  }
  a {
    display: flex;
    div {
      white-space: nowrap;
      margin-right: 0.625rem;
      line-height: 1.25rem;
    }
  }
  a + a {
    margin-left: 1rem;
  }
`;

const Header = ({ children }: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const scrollContainer = document.querySelector('.container');

    const handleScroll = debounce(() => {
      const currentScrollPos = scrollContainer!.scrollTop;
      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 100) ||
          currentScrollPos < 10
      );

      setPrevScrollPos(currentScrollPos);
    }, 20);

    scrollContainer && scrollContainer.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible]);

  const onHamburgerClick = () => {
    const navEl = document.querySelector('nav');
    menuOpen
      ? (navEl!.style.display = 'none')
      : (navEl!.style.display = 'flex');
    setMenuOpen(!menuOpen);
  };
  return (
    <HeaderStyles sticky={prevScrollPos < 200} topValue={visible ? 0 : -92}>
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="ReefCloud" />
        </Link>
      </div>
      <div className="menu">
        {children}
        <LoginBtnStyles>
          <Button href="/dashboard/" primary>
            <div>Dashboard</div>
            <img src={loginIcon} alt="External link" />
          </Button>
          <Button href="/app/">Login to data portal</Button>
        </LoginBtnStyles>
      </div>
      <Hamburger onClick={onHamburgerClick} />
    </HeaderStyles>
  );
};

export default Header;
