import { isMobile } from 'mobile-device-detect';
import { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { gsap } from 'gsap';
import { Helmet } from 'react-helmet';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';

import About from './pages/About';
import Contact from './pages/Contact';
import Cursor from './components/Cursor';
import Footer from './components/Footer';
import GlobalStyles from './styles/GlobalStyles';
import GradientAnimation from './utils/GradientAnimation';
import Header from './components/Header';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import InnerStyles from './styles/InnerStyles';
import Navigation from './components/Navigation';
import { NavigationItem } from './types';
import NewsletterPopup from './components/NewsletterPopup';

const ROUTES: Array<NavigationItem> = [
  {
    Component: Home,
    path: '/',
    name: 'Home',
  },
  {
    Component: About,
    path: '/about',
    name: 'About',
  },
  {
    Component: HowItWorks,
    path: '/how-it-works',
    name: 'How it works',
  },
  {
    Component: null,
    path: 'https://reefcloud.github.io/resources/',
    name: 'Get started',
  },
  {
    Component: Contact,
    path: '/contact',
    name: 'Contact',
  },
];
interface Match {
  match: { name: string };
}

const App = () => {
  const [statsData, setStatsData] = useState({});
  const endpointUrl: any =
    process.env.NODE_ENV === 'development'
      ? '/data/stats.json'
      : process.env.REACT_APP_STATS_ENDPOINT_URL;
  const containerRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const bgAnimationRef: any = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    bgAnimationRef.current = new GradientAnimation(
      canvasRef.current,
      containerRef.current
    );

    bgAnimationRef.current.fadeIn();

    loadStats();
  }, []);
  const fadeIn = () => {
    scrollUp();
    bgAnimationRef.current.fadeIn();
  };
  const fadeOut = () => {
    scrollUp();
    bgAnimationRef.current.fadeOut();
  };
  const scrollUp = () => {
    document.querySelector('.container')!.scroll(0, 0);
  };
  const loadStats = async () => {
    await fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        setStatsData({ ...data });
      })
      .catch((error) => {
        console.error('Error loading data', error);
      });
  };

  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ReefCloud</title>
        <meta name="description" content="ReefCloud"></meta>
        <meta
          name="google-site-verification"
          content="hDfArKsFxw3p0l8kNGlVet6bdCh1lkl_1m3ShxrD5VE"
        />
      </Helmet>
      <GlobalStyles />
      <canvas ref={canvasRef} style={{ position: 'fixed' }}></canvas>
      <div className="container" id="container" ref={containerRef}>
        {ROUTES.map(({ path, Component }) => {
          let props = {};
          if (path === '/') {
            props = { stats: { ...statsData } };
          }
          if (Component === null) return;
          return (
            <Route key={path} path={path} exact>
              {({ match }: Match) => (
                <CSSTransition
                  in={match != null}
                  timeout={1600}
                  classNames="page"
                  unmountOnExit
                  onExit={fadeOut}
                  onEntered={fadeIn}
                >
                  <div className="page">
                    <InnerStyles>
                      <Header>
                        <Navigation navItems={ROUTES} />
                      </Header>
                      <Component {...props} />
                    </InnerStyles>
                    <Footer navItems={ROUTES} />
                  </div>
                </CSSTransition>
              )}
            </Route>
          );
        })}
      </div>
      {!isMobile && <Cursor />}
      <NewsletterPopup />
    </Router>
  );
};

export default App;
