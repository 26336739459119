import { animateScroll } from './animate-scroll';

const logError = () =>
  console.error(
    `Invalid element, are you sure you've provided an element id or ref?`
  );

const getElementPosition = (element: HTMLElement) => element.offsetTop;

interface scrollToParams {
  id: string | undefined;
  ref: any;
  duration: number;
  scrollContainer: HTMLDivElement | null;
}

export const scrollTo = ({
  id,
  ref = null,
  duration = 3000,
  scrollContainer,
}: scrollToParams) => {
  // the position of the scroll bar before the user clicks the button
  const initialPosition = scrollContainer!.scrollTop;

  // decide what type of reference that is
  // if neither ref or id is provided, set element to null
  const element = ref ? ref.current : id ? document.getElementById(id) : null;

  if (!element) {
    // log error if the reference passed is invalis
    logError();
    return;
  }

  animateScroll({
    targetPosition: getElementPosition(element),
    initialPosition,
    duration,
    scrollContainer,
  });
};
