import { createGlobalStyle } from 'styled-components';

import device from '../utils/device';

import AvenirNextDemiBoldWoff from '../fonts/AvenirNext-DemiBold.woff';
import AvenirNextDemiBoldWoff2 from '../fonts/AvenirNext-DemiBold.woff2';
import AvenirNextLTProRegularWoff from '../fonts/AvenirNextLTPro-Regular.woff';
import AvenirNextLTProRegularWoff2 from '../fonts/AvenirNextLTPro-Regular.woff2';
import AvenirNextRegularWoff from '../fonts/AvenirNext-Regular.woff';
import AvenirNextRegularWoff2 from '../fonts/AvenirNext-Regular.woff2';
import AvenirNextItalicWoff from '../fonts/AvenirNext-Italic.woff';
import AvenirNextItalicWoff2 from '../fonts/AvenirNext-Italic.woff2';
import AvenirNextDemiBoldItalicWoff from '../fonts/AvenirNext-DemiBoldItalic.woff';
import AvenirNextDemiBoldItalicWoff2 from '../fonts/AvenirNext-DemiBoldItalic.woff2';
import Fraunces72ptSoftSemiBoldWoff from '../fonts/Fraunces72ptSoft-SemiBold.woff';
import Fraunces72ptSoftSemiBoldWoff2 from '../fonts/Fraunces72ptSoft-SemiBold.woff2';
import Fraunces72ptSoftRegularWoff from '../fonts/Fraunces72ptSoft-Regular.woff';
import Fraunces72ptSoftRegularWoff2 from '../fonts/Fraunces72ptSoft-Regular.woff2';

const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: 'Avenir Next';
      src: url(${AvenirNextDemiBoldWoff2}) format('woff2'),
          url(${AvenirNextDemiBoldWoff}) format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Avenir Next';
      src: url(${AvenirNextLTProRegularWoff2}) format('woff2'),
          url(${AvenirNextLTProRegularWoff}) format('woff'),
          url(${AvenirNextRegularWoff2}) format('woff2'),
          url(${AvenirNextRegularWoff}) format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Avenir Next';
      src: url(${AvenirNextItalicWoff2}) format('woff2'),
          url(${AvenirNextItalicWoff}) format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Avenir Next';
      src: url(${AvenirNextDemiBoldItalicWoff2}) format('woff2'),
          url(${AvenirNextDemiBoldItalicWoff}) format('woff');
      font-weight: 600;
      font-style: italic;
      font-display: swap;
  }

  @font-face {
      font-family: 'Fraunces';
      src: url(${Fraunces72ptSoftSemiBoldWoff2}) format('woff2'),
          url(${Fraunces72ptSoftSemiBoldWoff}) format('woff');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Fraunces';
      src: url(${Fraunces72ptSoftRegularWoff2}) format('woff2'),
          url(${Fraunces72ptSoftRegularWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
  }
  html {
    --white: #FFFFFF;
    --black: #000000;
    --darkPurple: #252039;
    --lightGreen: #00FFC2;
    --maxWidth: 1440px;
    --gutters: 0.938rem;
    --guttersLaptop: 2rem;
    --guttersLaptopL: 10.313rem;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
    cursor: none;
  }
  body {
    font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 1rem;
    color: var(--white);
    background-color: var(--darkPurple);
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  a {
    text-decoration: none;
    color: var(--white);
  }
  a:hover {
    text-decoration: underline;
  }
  .container {
    position: relative;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
  .page {
    position: absolute;
    width: 100%;
  }
  .page-enter {
    opacity: 0;
  }
  .page-enter-active {
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 2000ms;
  }
  .page-exit {
    opacity: 1;
  }
  .page-exit-active {
    opacity: 0;
    transition: opacity 400ms;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .ReactModal__Body--open {
    .page {
      filter: blur(4px);
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:linear-gradient(#252039, rgba(37,33,53,0.92),rgba(36,33,50,0.88));
    z-index: 10;
  }
  .modal-content {
    background: transparent;
    border: none;
    position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    WebkitOverflowScrolling: touch;
    outline: none;
    padding: 20px;
    /* Hide Scrollbar */
    overflow: scroll;    
    ::-webkit-scrollbar {
      display: none; /* Chrome */
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @media ${device.laptop} {
      width: 980px;
      margin: 14.375rem auto 0;
    }
  }
  .modal-subscribe {
    background: var(--darkPurple);
    border: none;
    position: absolute;
    WebkitOverflowScrolling: touch;
    outline: none;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .video-container {
    position: relative;
    max-height: 700px;
    width: 100%;
  }  
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export default GlobalStyles;
