import styled from 'styled-components';
import BaseSectionStyles from '../styles/BaseSectionStyles';

import { Partner } from '../types';
import device from '../utils/device';
import PartnersData from '../data/partner-data.json';

const SCIENCE = 'Our science partners';
const COLLABORATORS = 'Our collaborators';
const FUNDING = 'Our funding partners';

const SCIENCE_DATA: Array<Partner> = PartnersData.filter((item) =>
  item.section.includes(SCIENCE)
);

const COLLAB_DATA: Array<Partner> = PartnersData.filter((item) =>
  item.section.includes(COLLABORATORS)
);

const FUNDING_DATA: Array<Partner> = PartnersData.filter((item) =>
  item.section.includes(FUNDING)
);

const PartnersSectionStyles = styled(BaseSectionStyles)`
  padding: 5rem 0;
  background-color: var(--white);
  color: var(--black);
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 549px;
  @media ${device.laptop} {
    padding: 4.75rem 0;
  }
  .wrapper {
    max-width: var(--maxWidth);
    margin: 0 auto;
    div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin: 0 auto 5rem;
      @media ${device.laptopL} {
        width: 1004px;
        min-height: 131px;
        justify-content: left;
        margin: 0 auto 7.625rem;
      }
      min-height: 315px;
      //margin: 3.125rem 0 2.5rem;
      a:hover {
        img {
          opacity: 0.5;
        }
      }
      img {
        width: 158px;
        height: 104px;
        align-self: center;
        @media ${device.laptop} {
          width: 197px;
          height: auto;
        }
        opacity: 0;
        transition: opacity 1s ease;
      }
      img.active {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
    h3 {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 2.5rem;
      margin: 0 0 3rem 1.875rem;
      @media ${device.laptop} {
        font-size: 2.125rem;
        line-height: 3rem;
        margin: 0 0 3rem 10.313rem;
      }
    }
  }
`;

const PartnersSection = () => (
  <PartnersSectionStyles>
    <div className="wrapper">
      <h3>{SCIENCE}</h3>
      <div>
        {SCIENCE_DATA.map((partner) => (
          <a
            key={partner.id}
            href={partner.href}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`images/${partner.src}.png`}
              srcSet={`images/${partner.src}@2x.png 2x, images/${partner.src}@3x.png 3x`}
              alt={partner.alt}
              className="active"
            />
          </a>
        ))}
      </div>
      <h3>{COLLABORATORS}</h3>
      <div>
        {COLLAB_DATA.map((partner) => (
          <a
            key={partner.id}
            href={partner.href}
            target="_blank"
            rel="noreferrer"
          >
            <img
              key={partner.id}
              src={`images/${partner.src}.png`}
              srcSet={`images/${partner.src}@2x.png 2x, images/${partner.src}@3x.png 3x`}
              alt={partner.alt}
              className="active"
            />
          </a>
        ))}
      </div>
      <h3>{FUNDING}</h3>
      <div>
        {FUNDING_DATA.map((partner) => (
          <a
            key={partner.id}
            href={partner.href}
            target="_blank"
            rel="noreferrer"
          >
            <img
              key={partner.id}
              src={`images/${partner.src}.png`}
              srcSet={`images/${partner.src}@2x.png 2x, images/${partner.src}@3x.png 3x`}
              alt={partner.alt}
              className="active"
            />
          </a>
        ))}
      </div>
    </div>
  </PartnersSectionStyles>
);

export default PartnersSection;
