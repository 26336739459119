import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

const useGaTracker = (title?: string) => {
  useEffect(() => {
    const trackingId = process.env.REACT_APP_G4A_MEASUREMENT_ID;

    if (window.location.href.includes('localhost')) return;
    if (!trackingId) return;
    ReactGA4.initialize([{ trackingId }]);
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname,
      page_title: title ? `ReefCloud - ${title}` : 'ReefCloud',
    });
  }, []);
};

export default useGaTracker;
