import styled from 'styled-components';

import { scrollTo } from '../utils/scroll-to';
import ArrowIcon from '../images/arrow-icon.svg';

interface BackToTopProps {
  toId?: string;
  toRef?: any;
  duration: number;
  scrollContainer: HTMLDivElement | null;
}

const BackToTopStyles = styled.button`
  background: transparent;
  border: 1px solid var(--white);
  border-radius: 50%;
  width: 65px;
  height: 65px;
  background: rgba(255, 255, 255, 0);
  transition: background 0.5s ease-in;
  img {
    transition-property: transform;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.08);
    img {
      transform: translateY(-8px);
    }
  }
`;

const BackToTop = ({
  toId,
  toRef,
  duration,
  scrollContainer,
}: BackToTopProps) => {
  const onButtonClick = () => {
    scrollTo({ id: toId, ref: toRef, duration, scrollContainer });
  };
  return (
    <BackToTopStyles className="back-to-top" onClick={onButtonClick}>
      <img src={ArrowIcon} alt="Back to top of the page" />
    </BackToTopStyles>
  );
};

export default BackToTop;
