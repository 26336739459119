import styled from 'styled-components';

const SpacerStyles = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}vh`};
  width: 100%;
`;

interface SpacerProps {
  height?: number;
}

const Spacer = ({ height = 50 }: SpacerProps) => (
  <SpacerStyles height={height} />
);

export default Spacer;
