import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const BaseStyles = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  will-change: auto;
  z-index: 999;
`;

const CursorDotStyles = styled(BaseStyles)`
  width: 8px;
  height: 8px;
  top: -200px;
  left: -200px;
  background-color: var(--lightGreen);
`;

const CursorDotOutlineStyles = styled(BaseStyles)`
  width: 40px;
  height: 40px;
  border: 1px solid var(--lightGreen);
`;

const CursorGlowStyles = styled(BaseStyles)`
  overflow: hidden;
  display: flex;
  width: 600px;
  height: 600px;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  z-index: 0;
  div {
    background: #df2274;
    width: 260px;
    height: 260px;
    border-radius: 50%;
    filter: blur(80px);
  }
`;

const Cursor = () => {
  const delay = 3;

  const dot = useRef<HTMLDivElement>(null);
  const dotOutline = useRef<HTMLDivElement>(null);
  const dotGlow = useRef<HTMLDivElement>(null);

  const cursorVisible = useRef(true);
  const cursorEnlarged = useRef(false);

  const endX = useRef(-200); // Start off the screen
  const endY = useRef(-200); // Start off the screen
  const _x = useRef(0);
  const _y = useRef(0);

  const requestRef = useRef<number>(0);

  useEffect(() => {
    document.addEventListener('mousedown', mouseOverEvent);
    document.addEventListener('mouseup', mouseOutEvent);
    document.addEventListener('mousemove', mouseMoveEvent);
    document.addEventListener('mouseenter', mouseEnterEvent);
    document.addEventListener('mouseleave', mouseLeaveEvent);

    animateDotOutline();

    return () => {
      document.removeEventListener('mousedown', mouseOverEvent);
      document.removeEventListener('mouseup', mouseOutEvent);
      document.removeEventListener('mousemove', mouseMoveEvent);
      document.removeEventListener('mouseenter', mouseEnterEvent);
      document.removeEventListener('mouseleave', mouseLeaveEvent);

      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const toggleCursorVisibility = () => {
    if (dot.current && dotOutline.current && dotGlow.current) {
      if (cursorVisible.current) {
        dot.current.style.opacity = '1';
        dotOutline.current.style.opacity = '1';
        dotGlow.current.style.opacity = '0.25';
      } else {
        dot.current.style.opacity = '0';
        dotOutline.current.style.opacity = '0';
        dotGlow.current.style.opacity = '0';
      }
    }
  };

  const toggleCursorSize = () => {
    if (dot.current && dotOutline.current && dotGlow.current) {
      if (cursorEnlarged.current) {
        dot.current.style.transform = 'translate(-50%, -50%) scale(0.75)';
        dotOutline.current.style.transform = 'translate(-50%, -50%) scale(1.5)';
        dotGlow.current.style.transform = 'translate(-50%, -50%) scale(1.5)';
      } else {
        dot.current.style.transform = 'translate(-50%, -50%) scale(1)';
        dotOutline.current.style.transform = 'translate(-50%, -50%) scale(1)';
        dotGlow.current.style.transform = 'translate(-50%, -50%) scale(1)';
      }
    }
  };

  const mouseOverEvent = () => {
    cursorEnlarged.current = true;
    toggleCursorSize();
  };

  const mouseOutEvent = () => {
    cursorEnlarged.current = false;
    toggleCursorSize();
  };

  const mouseEnterEvent = () => {
    cursorVisible.current = true;
    toggleCursorVisibility();
  };

  const mouseLeaveEvent = () => {
    cursorVisible.current = false;
    toggleCursorVisibility();
  };

  const mouseMoveEvent = (e: MouseEvent) => {
    cursorVisible.current = true;
    toggleCursorVisibility();

    endX.current = e.pageX;
    endY.current = e.pageY;
    if (dot.current) {
      dot.current.style.top = endY.current + 'px';
      dot.current.style.left = endX.current + 'px';
    }
  };

  const animateDotOutline = () => {
    _x.current += (endX.current - _x.current) / delay;
    _y.current += (endY.current - _y.current) / delay;
    if (dotOutline.current && dotGlow.current) {
      dotOutline.current.style.top = _y.current + 'px';
      dotOutline.current.style.left = _x.current + 'px';
      dotGlow.current.style.top = _y.current + 'px';
      dotGlow.current.style.left = _x.current + 'px';
    }

    requestRef.current = requestAnimationFrame(animateDotOutline);
  };

  return (
    <>
      <CursorGlowStyles ref={dotGlow}>
        <div></div>
      </CursorGlowStyles>
      <CursorDotOutlineStyles ref={dotOutline}></CursorDotOutlineStyles>
      <CursorDotStyles ref={dot}></CursorDotStyles>
    </>
  );
};

export default Cursor;
