import styled from 'styled-components';

import device from '../utils/device';

const SectionTitleStyles = styled.div<{
  inverted?: boolean;
  small?: boolean;
}>`
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: ${(props) => (props.inverted ? 'var(--black)' : 'var(--white)')};
  @media ${device.laptop} {
    font-size: ${(props) => (props.small ? '2.125rem' : '3.25rem')};
    line-height: 3rem;
  }
  font-weight: 500;
  align-self: flex-start;
  display: block;
  margin-bottom: 1.25rem;
`;

export default SectionTitleStyles;
