import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import SubscribeModal from './SubscribeModal';

const NewsletterPopup = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const isModalClosed = localStorage.getItem('newsletterModalClosed');
    if (!isModalClosed) {
      const timer = setTimeout(() => {
        openModal();
      }, 5000); // Show popup after 5 seconds

      return () => clearTimeout(timer);
    }
  }, []);

  Modal.setAppElement('#root');

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    localStorage.setItem('newsletterModalClosed', 'true');
  };

  return (
    <Modal
      closeTimeoutMS={200}
      contentLabel="modal"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <SubscribeModal onCloseHandler={closeModal} />
    </Modal>
  );
};

export default NewsletterPopup;
