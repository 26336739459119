import { scrollTo } from '../utils/scroll-to';
import Button from './Button';

interface ScrollToButtonProps {
  toId?: string;
  toRef?: any;
  duration: number;
  scrollContainer: HTMLDivElement | null;
  children: string;
  className: string;
  primary?: boolean;
}

const ScrollToButton = ({
  toId,
  toRef,
  duration,
  scrollContainer,
  children,
  className,
  primary,
}: ScrollToButtonProps) => {
  const handleClick = () =>
    scrollTo({ id: toId, ref: toRef, duration, scrollContainer });

  return (
    <Button primary={primary} className={className} onClick={handleClick}>
      {children}
    </Button>
  );
};

export default ScrollToButton;
