import styled from 'styled-components';

import device from '../utils/device';
import ModalStyles from './ModalStyles';

const ProfileModalStyles = styled(ModalStyles)`
  .profile-image > img {
    width: 100%;
    @media ${device.laptop} {
      width: 420px;
      height: 420px;
    }
  }
  .profile-content {
    @media ${device.laptop} {
      padding-left: 3.125rem;
    }
    .profile-name {
      display: block;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 2.5rem;
    }
    .profile-position {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem;
      color: var(--lightGreen);
    }
    .profile-org {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem;
      color: var(--lightGreen);
    }
    .profile-bio {
      display: block;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
`;

export default ProfileModalStyles;
