import { gsap } from 'gsap';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { animateSection } from '../utils/animation';
import Button from '../components/Button';
import Hero from '../components/Hero';
import PageSection from '../components/PageSection';
import PartnersCarousel from '../components/PartnersCarousel';
import QuotesCarousel from '../components/QuotesCarousel';
import ScrollToButton from '../components/ScrollToButton';
import StatsSection from '../components/StatsSection';

import ReefImage from '../images/reef-image.png';
import ReefImage2x from '../images/reef-image@2x.png';
import ReefImage3x from '../images/reef-image@3x.png';
import ReefImageTwo from '../images/reef-image2.png';
import ReefImageTwo2x from '../images/reef-image2@2x.png';
import ReefImageTwo3x from '../images/reef-image2@3x.png';
import ReefImageThree from '../images/reef-image3.png';
import ReefImageThree2x from '../images/reef-image3@2x.png';
import ReefImageThree3x from '../images/reef-image3@3x.png';
import ReefImageSix from '../images/reef-image6.png';
import ReefImageSix2x from '../images/reef-image6.png';
import ReefImageSix3x from '../images/reef-image6.png';
import useGaTracker from '../components/useGaTracker';
import YoutubeEmbed from '../components/YoutubeEmbed';
// import loginIcon from '../images/siteLoginMove.svg';

type HomeProps = {
  stats: {
    [key: string]: string;
  };
  children?: JSX.Element | Array<JSX.Element>;
};

const Home = ({ stats }: HomeProps) => {
  useGaTracker();
  const ref = useRef(null);
  const nextRef = useRef(null);
  const headerRef = useRef(null);
  const storyOneRef = useRef(null);
  const storyTwoRef = useRef(null);
  const storyThreeRef = useRef(null);
  const statsSectionRef = useRef(null);
  const finalStoryRef = useRef(null);
  const partnersRef = useRef(null);
  const videoOneRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    const elements = [
      ref.current,
      nextRef.current,
      videoOneRef.current,
      headerRef.current,
      storyOneRef.current,
      storyTwoRef.current,
      storyThreeRef.current,
      statsSectionRef.current,
      finalStoryRef.current,
    ];

    elements.forEach((el, index) => {
      const delay = index === 0 ? 1.5 : 0;
      const animateOnScroll = index === 0 ? false : true;
      animateSection(el!, animateOnScroll, delay);
    });

    // Partners scroll trigger
    const partnersTL = gsap.timeline({
      scrollTrigger: {
        scroller: '.container',
        pin: elements[elements.length - 1],
        pinType: 'fixed',
        pinReparent: true,
        start: 'top top',
        end: '+=200%',
        //scrub: true,
        trigger: elements[elements.length - 1],
      },
    });

    return function cleanup() {
      partnersTL.kill();
      elements.forEach((el) => gsap.killTweensOf(el));
    };
  }, []);
  return (
    <>
      <Hero ref={ref}>
        <h1 className="animate-reveal">
          Protecting the future of <span>coral reefs</span>
        </h1>
        <p className="animate-reveal">
          Harnessing the power of human collaboration and artificial
          intelligence, ReefCloud allows the world&apos;s coral reef monitoring
          community to work together in real time to improve the monitoring,
          reporting and conservation of our reefs.
        </p>
        <ScrollToButton
          className="animate-reveal"
          duration={1500}
          toRef={nextRef}
          scrollContainer={document.querySelector('.container')}
        >
          Dive deeper
        </ScrollToButton>
      </Hero>
      {/* uncomment and updated text for job ads */}
      {/* <Hero ref={ref}>
        <div className="job-container animate-reveal">
          <h4 className="title animate-reveal">Jobs at Reefcloud</h4>
          <table className="animate-reveal">
            <tbody>
              <tr>
                <td>
                  <strong>Monitoring Data Analyst</strong>
                </td>
                <td>Closing 08/09/2022</td>
                <td>
                  <Button
                    href="https://www.aims.gov.au/about/careers-aims/reefcloud-monitoring-data-analyst"
                    className="animate-reveal"
                  >
                    <span>Apply</span>
                    <img src={loginIcon} alt="External link" />
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Science Extension</strong>
                </td>
                <td>Closing 08/09/2022</td>
                <td>
                  <Button
                    href="https://www.aims.gov.au/about/careers-aims/reefcloud-science-extension"
                    className="animate-reveal"
                  >
                    <span>Apply</span>
                    <img src={loginIcon} alt="External link" />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Hero> */}
      <PageSection ref={nextRef}>
        <div className="article-image">
          <img
            className="animate-reveal"
            src={ReefImage}
            srcSet={`${ReefImage2x} 2x, ${ReefImage3x} 3x`}
            alt="Reef Image"
          />
        </div>
        <div className="section-content">
          <div className="title animate-reveal">What is ReefCloud?</div>
          <p className="copy animate-reveal">
            ReefCloud is a transformational digital tool that allows the
            world&apos;s coral reef monitoring community to work together in
            real time to improve reef monitoring and preservation. As an
            open-access platform ReefCloud can quickly and efficiently collate
            data to inform reef management decisions.
          </p>
          <Button
            onClick={() => {
              history.push('/how-it-works');
            }}
            className="animate-reveal"
          >
            Learn more
          </Button>
        </div>
      </PageSection>
      <PageSection ref={videoOneRef}>
        <div className="video-container animate-reveal">
          <YoutubeEmbed embedId="LhGzoPzk8qs" />
        </div>
      </PageSection>

      <PageSection fullscreen={false} noPadBottom={true} ref={headerRef}>
        <h3 className="animate-reveal">
          ReefCloud helps the global coral reef monitoring community to:
        </h3>
      </PageSection>
      <PageSection ref={storyOneRef} reverse noPadRight>
        <img
          className="animate-reveal"
          src={ReefImageTwo}
          srcSet={`${ReefImageTwo2x} 2x, ${ReefImageTwo3x} 3x`}
          alt="Reef Image"
        />
        <div className="section-content">
          <div className="title animate-reveal">
            Manage, analyse &amp; share monitoring data
          </div>
          <p className="copy animate-reveal">
            Users can share their photos and data with the world&apos;s coral
            reef monitoring community quickly and easily and get up-to-date
            snapshots of reef conditions in specific areas. Data sharing fosters
            greater collaboration between scientists and organisations.
          </p>
          <Button href="/app/" className="animate-reveal" primary>
            Upload photos
          </Button>
          <Button
            onClick={() => {
              history.push('/how-it-works');
            }}
            className="animate-reveal"
          >
            Learn more
          </Button>
        </div>
      </PageSection>
      <PageSection ref={storyTwoRef} noPadLeft>
        <img
          className="animate-reveal"
          src={ReefImageThree}
          srcSet={`${ReefImageThree2x} 2x, ${ReefImageThree3x} 3x`}
          alt="Reef Image"
        />
        <div className="section-content">
          <div className="title animate-reveal">Fast-track analysis</div>
          <p className="copy animate-reveal">
            Using automated technologies, ReefCloud fast-tracks the recording of
            changes to reef communities, helping to interpret, report and
            communicate about coral reef conditions across geographies.
          </p>
          <Button href="/dashboard/" className="animate-reveal">
            Explore our dashboard
          </Button>
        </div>
      </PageSection>
      <PageSection ref={storyThreeRef} reverse>
        <img
          className="animate-reveal"
          src={ReefImageSix}
          srcSet={`${ReefImageSix2x} 2x, ${ReefImageSix3x} 3x`}
          alt="Reef Image"
        />
        <div className="section-content">
          <div className="title animate-reveal">
            Collaborate, communicate &amp; conserve
          </div>
          <p className="copy animate-reveal">
            Collaboration is key. Users can work together and communicate widely
            about our understanding of where and how coral reefs are changing,
            to inform reef management decisions and help conserve our reefs.
          </p>
        </div>
      </PageSection>
      <StatsSection ref={statsSectionRef} stats={stats} />
      <QuotesCarousel />
      <PageSection ref={finalStoryRef}>
        <div className="call-to-action">
          <h2 className="animateReveal">
            Our reefs are facing their greatest challenge.
            <br />
            Let&apos;s work together to give them a fighting chance.{' '}
          </h2>
          <Button primary href="/app/" className="animate-reveal">
            Upload photos
          </Button>
        </div>
      </PageSection>
      <PartnersCarousel ref={partnersRef} />
    </>
  );
};

export default Home;
