import styled from 'styled-components';

import CloseIcon from '../images/close-icon.svg';
import device from '../utils/device';

const CloseButtonStyles = styled.button`
  position: fixed;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  @media ${device.laptop} {
    top: 40px;
    right: 40px;
  }
`;

interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton = ({ onClick }: CloseButtonProps) => (
  <CloseButtonStyles onClick={onClick}>
    <img src={CloseIcon} />
  </CloseButtonStyles>
);

export default CloseButton;
